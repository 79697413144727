import React, { Component } from 'react';

import Toolbar from '../../container/Navigation/Toolbar/Toolbar';
import LeftNavigationBar from '../../container/Navigation/LeftNavigationBar/LeftNavigationBar';
import BottomToolbar from '../../container/Navigation/BottomToolbar/BottomToolbar';

import './Layout.css';

class Layout extends Component {
    render() {
        return (
            <React.Fragment>
                <Toolbar/>
                <LeftNavigationBar showSystemMessage = {this.props.showSystemMessage}/>
                <BottomToolbar/>
                <main className="Content">
                    {this.props.children}
                </main>
            </React.Fragment>
        );
    }
}

export default Layout;