import React, { Component } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Menu } from 'react-feather';
import { Link } from 'react-router-dom';

class NavigationDropdown extends Component {

    state = {
        dropdownOpen: false
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    className="dropdown-toggle"
                >
                    <Menu width={16} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="font-weight-bold text-dark">Navigation</DropdownItem>
                    <DropdownItem divider className="m-0" />
                    <Link to="/" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Dashboard</Link>
                    <DropdownItem divider className="m-0" />
                    <Link to="/campaigns" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Campaigns</Link>
                    <DropdownItem divider className="m-0" />
                    <Link to="/reports" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Reports</Link>
                    <DropdownItem divider className="m-0" />
                    <Link to="/invoices" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Invoices</Link>
                    <DropdownItem divider className="m-0" />
                    <Link to="/integration" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Integration</Link>
                    <DropdownItem divider className="m-0" />
                    <Link to="/blog" className="dropdown-news-item dropdown-item" onClick={this.toggle}>Blog</Link>
                </DropdownMenu>
            </Dropdown>
        );
    }
}


export default NavigationDropdown;