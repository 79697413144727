import React, { Component } from 'react';

class FileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filePath: "",
            fileDescription: "",
            selectedFile: ""
        }
    }

    handleFileSelection = (e) => {
        this.setState({ filePath: e.target.value, selectedFile: e.target.files[0] });
    }

    onDescriptionChange = (e) => {
        this.setState({ fileDescription: e.target.value });
    }

    handleFileSubmission = (e) => {
        let fileElement = e.target.mb_file;

        e.preventDefault();
        const data = new FormData();
        data.append('mb_file', this.state.selectedFile, this.state.selectedFile.name);
        data.append('file_description', this.state.fileDescription);

        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/upload`, {
            body: data,
            method: 'POST',
            credentials: "include",
            headers: {
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    // Clear all the details upon sucessful submission
                    this.setState({ filePath: "", fileDescription: "", selectedFile: "" }, () => {
                        fileElement.value = "";
                        this.props.showSystemMessage('success', result.message);
                    });

                } else if (result["success"] === false) {
                    if (result.code === 415) {  // If file type is not allowed, then clear everything
                        this.setState({ filePath: "", fileDescription: "", selectedFile: "" }, () => {
                            fileElement.value = "";
                        });
                    }
                    this.props.showSystemMessage('error', result.errors.message);
                } else {
                    this.props.showSystemMessage('error', 'Internal error while trying to upload file. Please try again.');
                }
            })
            .catch(error => {
                this.setState({ filePath: "", fileDescription: "", selectedFile: "" }, () => {
                    fileElement.value = "";
                    this.props.showSystemMessage('error', error.message);
                });
            });
    }

    render() {
        return (
            <div className="card my-2 max-width-750">
                <div className="card-header d-flex align-items-center">
                    <div className="flex-1">File Upload</div>
                </div>
                <div className="card-body">
                    <div className="alert alert-secondary" role="alert">
                        Allowed file types: pdf, doc, docx, xls, xlsx, xlt, xla, csv, txt
                    </div>
                    <form onSubmit={this.handleFileSubmission} encType="multipart/form-data" method="POST">
                        <div className="form-group">
                            <label htmlFor="description">Please describe what this file is for:</label>
                            <input className="form-control max-width" type="text" name="description" value={this.state.fileDescription} onChange={this.onDescriptionChange} required />
                        </div>
                        <div className="form-group custom-file" style={{ maxWidth: "250px" }}>
                            <input type="file" className="custom-file-input" name="mb_file" id="customFile" required onChange={this.handleFileSelection} />
                            <label style={{ whitespace: "nowrap", overflow: "hidden" }} className="custom-file-label" htmlFor="mb_file">{this.state.filePath ? this.state.filePath : "Choose File"}</label>
                        </div>
                        <div className="form-group pt-2">
                            <button className="btn btn-primary" type="submit">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default FileUpload;