import React from 'react';

import './UserDetails.css';

const userDetails = (props) => (
    <div className="UserDetails rounded-bottom p-2">
        <div className="text-primary font-weight-bold text-uppercase d-inline-block">{props.advertiser.name}</div>
        <div className="text-muted d-inline-block" style={{ paddingLeft: "4px" }}> @{props.advertiser.company} | Advertiser ID# {props.advertiser.advertiser_id}</div>
    </div>
);

export default userDetails;

