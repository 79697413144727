import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import Pagination from '../../component/UI/Pagination/Pagination';
import { verifyToken, clearLocalStorageAndReload } from '../../assets/JS/functions';

class Invoices extends Component {

    state = {
        currentBalance: 0,
        transactions: [],
        total: 0,
        dataLoaded: false,
        page: 1,
        totalPages: 1,
        resultsPerPage: 50
    }

    componentDidMount() {
        let urlParams = queryString.parse(this.props.location.search);

        let page;

        if (urlParams.page) {
            page = parseInt(urlParams.page);
        } else {
            page = 1;
        }

        let startRow = (page - 1) * this.state.resultsPerPage;
        let endRow = (page * this.state.resultsPerPage) - 1;

        this.setState({
            dataLoaded: true,
            page: page,
            startRow: startRow,
            endRow: endRow
        }, () => {
            this.fetchInvoices();
        });
    }

    fetchInvoices = () => {

        const authtoken = localStorage.getItem('mb-auth-token');
        verifyToken().then(result => {
            if (result === true) {
                return fetch(`${process.env.REACT_APP_API_URL}/accountBalance/report`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({ currentBalance: result.currentBalance, transactions: result.transactions, total: result.total, dataLoaded: true, totalPages: result.total_pages });
                        } else if (result["success"] === false) {
                            if (result.code !== 401) {
                                this.props.showSystemMessage("error", "There was an error fetching invoices. Please try again.");
                            }
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log("Error in Invoices: ", error);
            this.props.showSystemMessage("error", "Error while fetching invoices.");
        });
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    handlePageChange = (requestedPage = this.state.page) => {
        let page = requestedPage;
        let startRow = (page - 1) * this.state.resultsPerPage;
        let endRow = (page * this.state.resultsPerPage) - 1;

        this.setState({ page: page, startRow: startRow, endRow: endRow });
    }

    render() {
        return (
            <div className="card my-2">
                <div className="card-header">
                    Account Balance
                </div>

                <div className="card-body">
                    <Pagination
                        totalPages={this.state.totalPages}
                        currentPage={this.state.page}
                        handlePageChange={this.handlePageChange}
                    />
                    <div className="table-responsive">
                        <table className="table table-striped table-card">
                            <thead>
                                <tr>
                                    <th scope="col" width="150px">Transactions</th>
                                    <th scope="col" width="250px">Amount</th>
                                    <th scope="col" width="200px">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dataLoaded && this.state.transactions.length === 0 && this.state.currentBalance === 0 ?
                                    <tr>
                                        <td colSpan="4">You do not have any transactions.</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        <tr>
                                            <td>This Period</td>
                                            <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.currentBalance)}</td>
                                            <td>{moment().format("d MMM YYYY")}</td>
                                        </tr>
                                        { this.state.transactions.slice(this.state.startRow, this.state.endRow).map(transaction => (
                                            <tr>
                                                <td>{transaction.type}</td>
                                                <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(transaction.amount)}</td>
                                                <td>{moment(transaction.date).format("d MMM YYYY")}</td>                                            
                                            </tr>
                                        )) }
                                    </React.Fragment>                                    
                                }
                                <tr>
                                    <th className="font-weight-bold">Total:</th>
                                    <th>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.total)}</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </tbody>
                        </table>
                        <Pagination
                            totalPages={this.state.totalPages}
                            currentPage={this.state.page}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Invoices);