import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';
import { X } from 'react-feather';
import moment from 'moment';
import _ from 'lodash';

class ReportConversions extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: {}
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        })

        const mbToken = localStorage.getItem('mb-auth-token');

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/conversions?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`;

        if (this.props.offerId) {
            fetchUrl = fetchUrl + `&oid=${this.props.offerId}`
        }

        if (this.props.affiliateId) {
            fetchUrl = fetchUrl + `&aid=${this.props.affiliateId}`;
        }

        if (this.props.creativeId) {
            fetchUrl = fetchUrl + `&cid=${this.props.creativeId}`;
        }

        if (this.props.landingPageId) {
            fetchUrl = fetchUrl + `&lpid=${this.props.landingPageId}`;
        }

        /* Get the affiliate object from the DB and populate the state information */

        verifyToken().then(result => {
            if (result === true) {
                return fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                // Set the parent Reports.js state with the total pages for Pagination.
                                this.props.handleTotalPages(result.total_pages);

                                // Get the campaigns returned by this report
                                this.props.setOffers(_.map(_.uniqBy(result.report, function (e) { return e.campaign_id }), function (campaign) { return _.pick(campaign, ['campaign_id', 'campaign_name']) }))

                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report,
                                    campaign: result.campaign,
                                    creative: result.creative,
                                    landingPage: result.landing_page
                                });
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportConversions: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    getReportOutput = () => {

        let result = this.state.report;
        let affFilter = this.props.affiliateFilter;
        let offerFilter = this.props.offerFilter;
        let affMatch = new RegExp(affFilter, "g");
        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        if (affFilter !== undefined && affFilter !== null) {
            result = _.filter(result, function (item) {
                return item.publisher_id.toString().match(affMatch);
            });
        }

        if (offerFilter !== undefined && offerFilter !== null) {
            result = _.filter(result, function (item) {
                return parseInt(item.campaign_id) === parseInt(offerFilter)
            });
        }

        return result.slice(this.props.startRow, this.props.endRow).map(item => {
            return (
                <tr key={item.key_id} className={item.status === 'Reversed' ? 'table-danger' : ''}>
                    <td>{item.click_id}</td>
                    <td>{moment.utc(item.date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td><Link to={`/campaign/${item.campaign_id}`}>{item.campaign_name}</Link></td>
                    <td><Link to={`/reports/earnings?aid=${item.publisher_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{item.publisher_id}</Link></td>
                    <td>{item.data}</td>
                    <td>{item.status} {item.status === 'Reversed' ? <React.Fragment>on {moment.utc(item.reversal_date).format("YYYY-MM-DD HH:mm:ss")}</React.Fragment> : ''}</td>
                    <td><input className="form-control form-control-sm" type="text" value={item.subid1} disabled /></td>
                    <td><input className="form-control form-control-sm" type="text" value={item.subid2} disabled /></td>
                    <td>{item.status === 'Payable' ? moment.utc(item.payout_date).format("YYYY-MM-DD HH:mm:ss") : ''}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                    <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.rate)}</td>
                </tr>
            )
        });
    }

    render() {

        let campaignId = this.state.campaign ? this.state.campaign.campaign_id : 0;

        return (
            <React.Fragment>

                {this.state.dataLoaded && this.state.campaign &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Conversions Report for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.affiliateId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Affiliate ID: {this.props.affiliateId} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'affiliate', campaignId, 0, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.creativeId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Creative: {this.state.creative} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'creative', campaignId, this.props.affiliateId, 0, 0, this.props.startDate, this.props.endDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.landingPageId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Landing Page: {this.state.landingPage} &nbsp;
                        <Link to={this.props.getCancelUrl('conversions', 'landingPage', campaignId, this.props.affiliateId, 0, 0, this.props.startDate, this.props.endDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                <table className="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Click ID</th>
                            <th scope="col">Date/Time (EST)</th>
                            <th scope="col">Campaign</th>
                            <th scope="col">Affiliate ID</th>
                            <th scope="col">Data</th>
                            <th scope="col">Status</th>
                            <th scope="col">SubId 1</th>
                            <th scope="col">SubId 2</th>
                            <th scope="col">Payment Date (EST)</th>
                            <th scope="col" className="text-right">Sales</th>
                            <th scope="col" className="text-right">CPA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colSpan="10">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        {this.getReportOutput()}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colSpan="11"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>

            </React.Fragment>
        );
    }
}

export default ReportConversions;