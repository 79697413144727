import React, { Component } from 'react';

import { Modal } from 'reactstrap';

import CreateUser from './CreateUser/CreateUser';
import Loading from '../../component/UI/Loading/Loading';

class Users extends Component {

    state = {
        users: [],
        dataLoaded: false,
        openCreateUserModal: false
    }

    toggleCreateUserModal = () => {
        this.setState({ openCreateUserModal: !this.state.openCreateUserModal });
    }

    closeModalAndUpdateUsers = () => {
        this.setState({ openCreateUserModal: false }, () => {
            this.fetchUsers();
        });
    }

    fetchUsers = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/advertiser/users`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({ users: result.users, dataLoaded: true })
                } else if (result["success"] === false) {
                    this.setState({ dataLoaded: true }, () => {
                        this.props.showSystemMessage('error', result.errors.message);
                    });
                } else {
                    this.setState({ dataLoaded: true }, () => {
                        this.props.showSystemMessage('error', 'There was an error while fetching users.');
                    });
                }
            }).catch(error => {
                console.log('Error in Users: ', error);
                this.props.showSystemMessage('error', 'There was an error while fetching users.');
            });
    }

    componentDidMount() {
        this.fetchUsers();
    }

    deleteUser = (user_id) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/user/deleteuser`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                user_id: user_id
            })
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({ dataLoaded: false }, () => {
                        this.props.showSystemMessage('success', result.message);
                        this.fetchUsers();
                    });
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                }
            })
            .catch(error => {
                this.props.showSystemMessage('error', 'An internal error occurred while deleting the user.', error.message);
            });
    }

    handleUserDelete = (e) => {
        e.preventDefault();
        const user_id = e.target.form_user_id.value;
        this.deleteUser(user_id);
    }

    editRole = (userId, e) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/user/editrole`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                user_id: userId,
                role: e.target.value
            })
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.props.showSystemMessage('success', result.message);
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                }
            })
            .catch(error => {
                this.props.showSystemMessage('error', error.message);
            });
    }

    render() {
        return (
            <div className="card my-2">
                <Modal isOpen={this.state.openCreateUserModal} toggle={this.toggleCreateUserModal} className="modal-md">
                    <CreateUser showSystemMessage={this.props.showSystemMessage} modalClosed={this.closeModalAndUpdateUsers} />
                </Modal>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="font-weight-bold flex-1">User Accounts</div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={this.toggleCreateUserModal}>
                        Create User
                    </button>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dataLoaded ?
                                    this.state.users.map(user => {

                                        let selectedOption =
                                            (<select name="form_role" className="custom-select custom-select-sm mr-2" style={{ maxWidth: "150px" }} defaultValue={user.role} onChange={(e) => this.editRole(user.id, e)}>
                                                <option value="manager">Manager</option>
                                                <option value="user">User</option>
                                            </select>);

                                        return <tr key={user.id}>
                                            <td>{user.e_mail}</td>
                                            <td className="text-capitalize">{(user.role !== 'admin' && this.props.users.role === 'admin') ?
                                                <form method="POST" onSubmit={this.editRole}>
                                                    <input type="hidden" name="action" value="edit_role" />
                                                    <input type="hidden" name="form_user_id" value={user.id} />
                                                    {selectedOption}
                                                </form>
                                                :
                                                (user.role)
                                            }</td>
                                            <td>{user.status === 'A' ? 'Active' : 'Unknown'}</td>
                                            <td className="text-right">{this.props.users.role === 'admin' && user.role !== 'admin' ?
                                                <form method="DELETE" onSubmit={this.handleUserDelete}>
                                                    <input type="hidden" name="action" value="delete" />
                                                    <input type="hidden" name="form_user_id" value={user.id} />
                                                    <input type="submit" name="submit" value="Delete" className="btn btn-danger btn-sm" />
                                                </form>
                                                : null}</td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <Loading />
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Users;