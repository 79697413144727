import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';

import './ReportDropdown.css';

class ReportDropdown extends Component {
    
    state = {
        dropdownOpen: false        
    };

    toggle = () => {        
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }    

    render() { 
        return (
            <Dropdown
            className="position-absolute"
            style={{right:'5px'}}
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            direction="down">
                <DropdownToggle
                tag="a"
                onClick={this.toggle}
                data-toggle="dropdown"
                aria-expanded={this.state.dropdownOpen}
                className="report-dropdown-toggle"
                >
                    <ChevronDown size={20} />
                </DropdownToggle>
                <DropdownMenu className='py-0'>
                    {this.props.offerId === 0 && 
                        <React.Fragment>
                            <Link className="dropdown-item py-2" to={this.props.getReportLink('earnings', this.props.offerId, this.props.affiliateId, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)} onClick={this.toggle}>By Campaign</Link>
                            <DropdownItem divider className="m-0" />
                        </React.Fragment>
                    }
                    {this.props.affiliateId === 0 && 
                        <React.Fragment>
                            <Link className="dropdown-item py-2" to={this.props.getReportLink('affiliates', this.props.offerId, this.props.affiliateId, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)} onClick={this.toggle}>By Affiliate</Link>
                            <DropdownItem divider className="m-0" />
                        </React.Fragment>
                    }
                    {this.props.creativeId === 0 && this.props.offerId !== 0 &&
                    <React.Fragment>
                        <Link className="dropdown-item py-2" to={this.props.getReportLink('creatives', this.props.offerId, this.props.affiliateId, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)} onClick={this.toggle}>By Creative</Link>
                        <DropdownItem divider className="m-0" />
                    </React.Fragment>                    
                    }
                    {this.props.landingPageId === 0 && this.props.offerId !== 0 &&
                    <React.Fragment>
                        <Link className="dropdown-item py-2" to={this.props.getReportLink('landingPages', this.props.offerId, this.props.affiliateId, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)} onClick={this.toggle}>By Landing Page</Link>                        
                    </React.Fragment>                    
                    }                    
                </DropdownMenu>
            </Dropdown>
        );
    }
}
 
export default ReportDropdown;