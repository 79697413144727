import React, { Component } from 'react';

class CreateUser extends Component {

    state = {
        email: '',
        role: 'manager',
        password: ''
    }

    handleCreateUser = (e) => {
        e.preventDefault();

        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/user/adduser`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                email: this.state.email,
                role: this.state.role,
                password: this.state.password
            })
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.props.showSystemMessage('success', result.message);
                    this.props.modalClosed();
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                    this.props.modalClosed();
                }
            })
            .catch(error => {
                this.props.showSystemMessage('error', error.message);
            });
    }

    handleRoleSelection = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, role: e.target.value });
    }

    handleInputChange = (e) => {
        if (e.target.name === 'form_email') {
            this.setState({ ...this.state, email: e.target.value });
        } else if (e.target.name === 'form_password') {
            this.setState({ ...this.state, password: e.target.value });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header">Create User</div>
                <div className="modal-body">
                    <form method="POST" onSubmit={this.handleCreateUser}>
                        <table className="table table-card table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="row">E-Mail</th>
                                    <td style={{ width: "85%" }}>
                                        <input name="form_email" type="email" className="form-control form-control-sm" onChange={this.handleInputChange} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Role</th>
                                    <td>
                                        <select name="form_role" className="custom-select custom-select-sm mr-2 mx-width-150" onChange={this.handleRoleSelection}>
                                            <option value="manager">Manager</option>
                                            <option value="user">User</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Password</th>
                                    <td style={{ width: "85%" }}>
                                        <input name="form_password" className="form-control form-control-sm" type="password" onChange={this.handleInputChange} required />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">&nbsp;</th>
                                    <td>
                                        <input name="submit" type="submit" className="btn btn-primary btn-sm" value="Save" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateUser;