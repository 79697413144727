import React, { Component } from 'react';

import { Prompt } from 'react-router';

import Countries from '../../../component/FormElements/Countries';
import CountryList from '../../../data/countrylist';
import StateList from '../../../data/statelist';
import StatesProvinces from '../../../component/FormElements/StatesProvinces';

class AccountInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            accountInfo: {
                name: this.props.accountInfo.name,
                streetAddress1: this.props.accountInfo.address,
                streetAddress2: this.props.accountInfo.address_two,
                city: this.props.accountInfo.city,
                state: this.getState(this.props.accountInfo.country_code),
                country: {
                    value: this.props.accountInfo.country_code,
                    text: this.getCountry(this.props.accountInfo.country_code)
                },
                zipCode: this.props.accountInfo.zipcode,
                phone: this.props.accountInfo.phone_num,
                mobilePhone: this.props.accountInfo.mobile_num
            },
            isFormHalfEdited: false         // To show incomplete form navigation prompt
        }
    }

    getCountry = (countryCode) => {
        const country = CountryList.countries.filter(country => country.code === countryCode)[0].name;
        return country;
    }

    getState = (countryCode) => {
        let state;
        if (countryCode === 'US' || countryCode === 'CA') {
            state = {
                value: StateList.states.filter(state => state.value === this.props.accountInfo.state)[0].value,
                text: this.props.accountInfo.state
            }
        } else {
            state = {
                value: '',
                text: ''
            }
        }
        return state;
    }

    saveSettings = (e) => {
        e.preventDefault();

        this.props.editName(e.target.name.value);

        this.setState({
            editing: !this.state.editing,
            isFormHalfEdited: false,
            accountInfo: {
                name: e.target.name.value,
                streetAddress1: e.target.address1.value,
                streetAddress2: e.target.address2.value,
                city: e.target.city.value,
                state: {
                    value: e.target.state.value,
                    text: e.target.state.options[e.target.state.selectedIndex].text
                },
                country: {
                    value: e.target.country.value,
                    text: e.target.country.options[e.target.country.selectedIndex].text
                },
                zipCode: e.target.zipcode.value,
                phone: e.target.phone.value,
                mobilePhone: e.target.mobilephone.value
            }
        }, () => {

            const authtoken = localStorage.getItem('mb-auth-token');

            fetch(`${process.env.REACT_APP_API_URL}/advertiser/accountInfo`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                },
                body: JSON.stringify({
                    accountInfo: this.state.accountInfo
                })
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        this.props.showSystemMessage('success', result.message);
                    } else if (result["success"] === false) {
                        this.setState({ ...this.state, editing: true }, () => {
                            this.props.showSystemMessage('error', result.errors.message);
                        });
                    } else {
                        this.props.showSystemMessage('error', result.errors.message);
                    }
                })
                .catch(error => this.props.showSystemMessage('error', error.message));
        });
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({ editing: !this.state.editing, isFormHalfEdited: false });
    }

    inputChangeHandler = (e) => {
        if (e.target.name === 'phone' || e.target.name === "mobilephone") {
            this.validatePhone(e.target);
        }

        this.setState({ isFormHalfEdited: true });
    }

    validatePhone = (input_phone) => {
        input_phone.setCustomValidity("");

        const phone = input_phone.value;

        if (input_phone.name === "phone" && !phone) {
            return true;
        }

        const validate = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone);

        if (input_phone.checkValidity() === false) {
            return false;
        } else if (!validate) {
            input_phone.setCustomValidity("This phone number is invalid.");
            return false;
        } else {
            return true;
        }
    }

    render() {
        return (
            <div className="card my-2">
                <Prompt
                    when={this.state.isFormHalfEdited}
                    message="Are you sure you want to leave? Changes you made might not be saved."
                />
                <form onSubmit={this.saveSettings} method="POST">
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Account Information</div>
                        <div className="text-right">
                            {this.state.editing ?
                                <React.Fragment>
                                    <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                    <button className="btn btn-success btn-sm" type="submit">Save</button>
                                </React.Fragment>
                                :
                                <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                            }
                        </div>
                    </div>

                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td className="no-border">Name</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.name}</div>
                                            :
                                            <input
                                                name="name"
                                                className="form-control form-control-sm"
                                                type="text"
                                                defaultValue={this.state.accountInfo.name}
                                                onChange={this.inputChangeHandler}
                                                required />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">Address</td>
                                    <td style={{ width: '70%' }} className="no-border">
                                        {this.state.editing === false ?
                                            <React.Fragment>
                                                <div>{this.state.accountInfo.streetAddress1}</div>
                                                {this.state.accountInfo.streetAddress2 !== '' &&
                                                    <div>{this.state.accountInfo.streetAddress2}</div>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <input
                                                    name="address1"
                                                    className="form-control form-control-sm mb-2"
                                                    type="text"
                                                    defaultValue={this.state.accountInfo.streetAddress1}
                                                    onChange={this.inputChangeHandler}
                                                    required />
                                                <input
                                                    name="address2"
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    defaultValue={this.state.accountInfo.streetAddress2}
                                                    onChange={this.inputChangeHandler} />
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">City</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.city}</div>
                                            :
                                            <input
                                                name="city"
                                                className="form-control form-control-sm"
                                                type="text"
                                                defaultValue={this.state.accountInfo.city}
                                                onChange={this.inputChangeHandler}
                                                required />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">State</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.state.value}</div>
                                            :
                                            <select
                                                name="state"
                                                className="form-control form-control-sm"
                                                defaultValue={this.state.accountInfo.state.value}
                                                onChange={this.inputChangeHandler}>
                                                <option value=""></option>
                                                <StatesProvinces />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">Country</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.country.value}</div>
                                            :
                                            <select
                                                name="country"
                                                className="form-control form-control-sm"
                                                defaultValue={this.state.accountInfo.country.value}
                                                onChange={this.inputChangeHandler}
                                                required>
                                                <option value=""></option>
                                                <Countries />
                                            </select>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">Zip Code</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.zipCode}</div>
                                            :
                                            <input
                                                name="zipcode"
                                                className="form-control form-control-sm"
                                                type="text"
                                                defaultValue={this.state.accountInfo.zipCode}
                                                onChange={this.inputChangeHandler}
                                                required />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">Phone</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.phone}</div>
                                            :
                                            <input
                                                name="phone"
                                                className="form-control form-control-sm"
                                                type="text"
                                                defaultValue={this.state.accountInfo.phone}
                                                onChange={this.inputChangeHandler}
                                                placeholder="xxx-xxx-xxxx"
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">Mobile Phone</td>
                                    <td style={{ width: '70%' }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.accountInfo.mobilePhone}</div>
                                            :
                                            <input
                                                name="mobilephone"
                                                className="form-control form-control-sm"
                                                type="text"
                                                defaultValue={this.state.accountInfo.mobilePhone}
                                                onChange={this.inputChangeHandler}
                                                required />
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default AccountInformation;