import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { User, ChevronDown } from "react-feather";

import './AccountDropdown.css';

class AccountDropdown extends Component {

    state = {
        isDropdownOpen: false
    }

    toggle = () => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }

    logout = () => {
        //localStorage.clear();
        localStorage.removeItem('mb-aid');
        localStorage.removeItem('mb-auth-token');
        localStorage.removeItem('mb-t');
        /* Finally, reload the page. */
        window.location.reload();
    }

    render() {
        return (
            <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggle} className="dropdown">
                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.isDropdownOpen}
                    className="dropdown-toggle">

                    <User width="16" height="16" viewBox="0 0 24 24" fill="none" strokeWidth="3" />
                    <ChevronDown size={16} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="font-weight-bold text-dark">System</DropdownItem>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/settings" onClick={this.toggle}>Account Settings</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/users" onClick={this.toggle}>Manage Users</Link>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/fileupload" onClick={this.toggle}>File Upload</Link>
                    <DropdownItem divider className="m-0" />
                    <DropdownItem tag="a" href="#" className="dropdown-news-item" onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default AccountDropdown;