import React, { Component } from 'react';

import { Modal } from 'reactstrap';
import { Copy, HelpCircle } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import IntegrationModal from './IntegrationModal/IntegrationModal';
import IntegrationPixel from './IntegrationPixel/IntegrationPixel';
import IntegrationMBTag from './IntegrationMBTag/IntegrationMBTag';

class CampaignRates extends Component {

    state = {
        openPostbackModal: false,
        openIntegrationModal: false,
        rateInModal: {}     // Holds rates whose postback is clicked
    }

    togglePostbackModal = (rate) => {
        this.setState({ openPostbackModal: !this.state.openPostbackModal, rateInModal: rate });
    }

    toggleIntegrationModal = () => {
        this.setState({ openIntegrationModal: !this.state.openIntegrationModal });
    }

    getModalType = () => {
        if (this.props.trackingType === 'S' || this.props.trackingType === 'G') {
            return <IntegrationModal trackingType={this.props.trackingType} trackingDomain={this.props.trackingDomain}/>;
        } else if (this.props.trackingType === 'L' || this.props.trackingType === 'I') {
            return <IntegrationPixel trackingType={this.props.trackingType} />;
        } else if (this.props.trackingType ===  'M'){
            return <IntegrationMBTag advertiserId={this.props.advertiserId} rates={this.props.rates} landingPages={this.props.landingPages}/>
        }
    }

    getCodeModalType = () => {

    }

    render() {
        return (
            <React.Fragment>

                <Modal isOpen={this.state.openPostbackModal} toggle={this.togglePostbackModal} className="modal-lg" style={{ fontSize: "0.9rem" }}>
                    <div className="modal-body">
                        <div className="font-weight-bold mb-2" >Postback </div>
                        {this.props.trackingType === 'M' &&
                            <div className="mb-3">Add the following code to your website's HTML anywhere <strong>after</strong> your MaxBounty tag.</div>
                        }                        
                        <div className="alert alert-light border">
                            <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>{this.state.rateInModal.postback}</pre>
                        </div>                        
                        <CopyToClipboard text={this.state.rateInModal.postback}>
                            <button className="btn btn-outline-primary btn-sm"><Copy size={15} /><span className="ml-1">Copy</span></button>
                        </CopyToClipboard>
                    </div>
                </Modal>
                <Modal isOpen={this.state.openIntegrationModal} toggle={this.toggleIntegrationModal} className="modal-lg">
                    {this.getModalType()}
                </Modal>
                <div className="card my-2">
                    <div className="card-header d-flex justify-content-between">
                        <div>Rates</div>
                        <button className="btn btn-sm btn-link" onClick={this.toggleIntegrationModal}><HelpCircle size={18} className="pr-1 pb-1" />Integration</button>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Type</th>
                                        <th scope="col" className="text-right">Rate</th>
                                        <th scope="col" className="text-right">Postback</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.rates.length < 1 ?
                                        <tr>
                                            <td colSpan="3">No rates found.</td>
                                        </tr> :
                                        this.props.rates.map((rate, i) => (rate.status === 'Active' &&
                                            <tr key={i}>
                                                <td>{rate.description}</td>
                                                <td>{rate.name}</td>
                                                <td>{rate.rate_type}</td>
                                                <td align="right">{rate.rate_type === 'Fixed' ? `$${parseFloat(rate.rate).toFixed(2)}`: `${rate.rate}%` }</td>
                                                <td className="text-right"><button className="btn btn-sm btn-link pt-0 pr-0 text-nowrap" type="button" onClick={() => this.togglePostbackModal(rate)}>Get Code</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CampaignRates;
