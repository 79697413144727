
export const yesNo = (value) => {
    if (value === 'Y') {
        return "Yes"
    } else {
        return "No"
    }
}

export function debounce(func, wait, immediate) {
    let timeout;

    return function () {
        let context = this, args = arguments;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    }
}

export const getCreativeName = (creativeType, creativeId, width, height) => {
    let creativeName;
    if (creativeType === 'B') {
        creativeName = `Banner_${creativeId} (${width} x ${height})`
    } else if (creativeType === 'F') {
        creativeName = `Flash_Banner_${creativeId} (${width} x ${height})`
    } else if (creativeType === 'E') {
        creativeName = `E-Mail_${creativeId}`
    } else if (creativeType === 'H') {
        creativeName = `HTML_${creativeId}`
    } else if (creativeType === 'T') {
        creativeName = `Text_${creativeId}`
    } else {
        if (creativeId === process.env.REACT_APP_SEARCH_CREATIVE_ID) {
            creativeName = "Search Link"
        } else if (creativeId === process.env.REACT_APP_CONTEXTUAL_CREATIVE_ID) {
            creativeName = "Contextual Link"
        } else if (creativeId === process.env.REACT_APP_RAW_CREATIVE_ID) {
            creativeName = "Raw Link"
        } else {
            creativeName = creativeId
        }
    }
    return creativeName;
}

export function clearLocalStorageAndReload() {
    localStorage.removeItem('mb-auth-token');
    // window.location.replace('http://localhost:3000/dashboard'); // Redirect to dashboard page where login page will be shown if user is unauthenticated
    window.location.reload();
}

export function verifyToken() {
    const authtoken = localStorage.getItem('mb-auth-token');

    return fetch(`${process.env.REACT_APP_API_URL}/application/verifyToken`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'x-app-token': authtoken
        }
    })
        .then(res => res.json())
        .then(result => {
            if (result["success"] === false) {
                if (result.code === 401) {
                    return false;
                } else {
                    return false;
                }
            }
            return true;
        });
}