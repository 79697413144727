import React from 'react';

import { Link } from 'react-router-dom';
import { HelpCircle } from 'react-feather';

import integrationImg from '../../../../assets/images/integration.jpg';

const mbIntegrationModal = (props) => (
    <div>
        <div className="modal-header">
            <h5 className="font-weight-bold">Integrating with MaxBounty</h5>
        </div>
        <div className="modal-body">
            <div className="py-3">
                <p>Integrating your site with the MaxBounty Advertising Platform will allow you to properly track all the leads we send you.</p>
                <p>The method of integration depends on the type of tracking your campaigns use, so <strong>detailed instructions can be found on the campaign details page</strong>. You can access your list of campaigns by clicking <Link to="/campaigns" onClick={props.modalClosed}>here</Link>.</p>
                <p>Once you're on the campaign details page, click the "<HelpCircle size={15}/> Integration" link in the rates section. This will explain how to integrate your campaign with MaxBounty.</p>
                <div className="text-center"><img src={integrationImg} alt="integration" /></div>
            </div>
        </div>
    </div>
);

export default mbIntegrationModal;