import React, { Component } from 'react';

import CampaignInfo from './CampaignInfo/CampaignInfo';
import CampaignRates from './CampaignRates/CampaignRates';
import LandingPages from './LandingPages/LandingPages';
import Creatives from './Creatives/Creatives';
import TodayStats from './TodayStats/TodayStats';
import MostRecentLeads from './MostRecentLeads/MostRecentLeads';
import Loading from '../../component/UI/Loading/Loading';

import './CampaignPage.css';

class CampaignPage extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        campaignData: '',
        todayStats: {}
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCampaignData(this.props);
    }

    getCampaignData = () => {
        const authToken = localStorage.getItem('mb-auth-token');
        const urlParams = this.props.campaign_id;

        fetch(`${process.env.REACT_APP_API_URL}/campaign/${urlParams}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => {
                return res.json()
            })
            .then(result => {
                if (result["success"] === true) {
                    if (this._isMounted) {
                        this.setState({ campaignData: result, dataLoaded: true });
                    }
                } else if (result["success"] === false) {
                    if (this._isMounted) {
                        this.setState({ dataLoaded: true });
                    }
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                    if (this._isMounted) {
                        this.setState({
                            dataLoaded: true
                        });
                    }
                }
            })
            .catch(error => {
                this.props.showSystemMessage('error', error.message);
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true
                    });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.dataLoaded && this.state.campaignData ?
                    <div className="overflow-hidden">
                        <div className="row">
                            <div className="col-md-4 split-left">
                                <CampaignInfo data={this.state.campaignData} showSystemMessage={this.props.showSystemMessage} />
                                <CampaignRates advertiserId={this.props.advertiserId} rates={this.state.campaignData.commission_rates} trackingType={this.state.campaignData.details.tracking_type} trackingDomain={this.state.campaignData.domains.tracking_domain} landingPages={this.state.campaignData.landing_pages} showSystemMessage={this.props.showSystemMessage} />
                                <LandingPages landingPages={this.state.campaignData.landing_pages} showSystemMessage={this.props.showSystemMessage} />
                                <Creatives creatives={this.state.campaignData.creatives} showSystemMessage={this.props.showSystemMessage} />
                            </div>
                            <div className="col-md-8 split-right">
                                <TodayStats campaignId={this.state.campaignData.campaign_id} showSystemMessage={this.props.showSystemMessage} />
                                <MostRecentLeads campaignId={this.state.campaignData.campaign_id} showSystemMessage={this.props.showSystemMessage} />
                            </div>
                        </div>
                    </div>
                    : this.state.dataLoaded && !this.state.campaignData ?
                        // This campaign doesn't exist
                        <div className="overflow-hidden">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card my-2">
                                        <div className="card-header">
                                            Error
                                        </div>
                                        <div className="card-body">
                                            This campaign doesn't exist.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        : !this.state.dataLoaded &&
                        //Data hasn't been loaded yet

                        <div style={{ marginTop: '4rem' }}>
                            <Loading />
                        </div>
                }
            </React.Fragment>
        );
    }
}

export default CampaignPage;