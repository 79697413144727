import React, { Component } from 'react';

import { Prompt } from 'react-router';

class UserInformation extends Component {

    constructor(props) {
        super(props);
        this.old_password = React.createRef();
        this.password2 = React.createRef();
        this.password3 = React.createRef();
        this.email = React.createRef();
    }

    state = {
        emailEditing: false,
        passwordEditing: false,
        userInfo: {
            email: this.props.userInfo.email_id
        },
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        isPasswordMatching: false,
        isFormHalfEdited: false
    }

    validate = (e) => {
        // The actual password validation will be done in the backend. We are just matching if both are same here.
        e.persist();
        const input_password2 = this.password2.current.value;
        const input_password3 = this.password3.current.value;

        if (input_password2.length > 0 && input_password3.length > 0) {

            if (input_password2 !== input_password3) {
                this.setState({ ...this.state, isPasswordMatching: false, isFormHalfEdited: true }, () => {
                    e.target.setCustomValidity('Your new password do not match.');
                    e.target.reportValidity();
                    return false;
                });

            } else {
                this.setState({ ...this.state, isPasswordMatching: true, isFormHalfEdited: true }, () => {
                    this.password3.current.setCustomValidity('');
                    this.password2.current.setCustomValidity('');
                });
                return true;
            }
        } else {
            return true;
        }
    }

    saveSettings = (e) => {
        e.preventDefault();

        let formType = e.target.name;

        if (e.target.name === 'emailForm') {
            this.setState({ emailEditing: false, userInfo: { email: e.target.email.value }, isFormHalfEdited: false }, () => {
                this.postTheData(formType);
            });

        } else if (e.target.name === 'passwordForm') {
            this.setState({ passwordEditing: false, ...this.state.userInfo, oldPassword: e.target.oldPassword.value, newPassword: e.target.password.value, confirmPassword: e.target.confirmPassword.value, isFormHalfEdited: false }, () => {
                this.postTheData(formType);
            });
        }
    }

    postTheData = (formType) => {

        let JSONbody;
        let postURL;

        const authtoken = localStorage.getItem('mb-auth-token');

        if (formType === 'emailForm') {
            postURL = `${process.env.REACT_APP_API_URL}/advertiser/emailSettings`;
            JSONbody = {
                email: this.state.userInfo.email
            };
        } else if (formType === 'passwordForm') {
            postURL = `${process.env.REACT_APP_API_URL}/advertiser/password`;
            JSONbody = {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
                newPasswordConfirm: this.state.confirmPassword
            }
        }

        fetch(postURL, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify(JSONbody)
        })
            .then(res => res.json())
            .then(result => {

                if (result["success"] === true) {
                    this.props.showSystemMessage('success', result.message);
                } else if (result["success"] === false) {
                    if (formType === 'emailForm') {
                        this.setState({ ...this.state, emailEditing: true }, () => {
                            this.props.showSystemMessage('error', result.errors.message);
                        });
                    } else {
                        this.setState({ ...this.state, passwordEditing: true }, () => {
                            this.props.showSystemMessage('error', result.errors.message);
                        });
                    }
                } else {
                    this.props.showSystemMessage('error', result.errors.message);
                }
            })
            .catch(error => {
                console.log('Error in email updation: ', error);
                this.props.showSystemMessage('error', "An internal error occurred while updating profile.");
            });
    }

    setEditing = (e) => {
        e.preventDefault();
        if (e.target.name === 'emailForm') {
            this.setState({ emailEditing: !this.state.emailEditing, isFormHalfEdited: false });
        } else if (e.target.name === 'passwordForm') {
            this.setState({ passwordEditing: !this.state.passwordEditing, isFormHalfEdited: false });
        }
    }


    validateEmail = () => {
        const input_email = this.email.current;

        input_email.setCustomValidity("");

        const emailAccount = input_email.value.split("@")[0] + "@";
        const emailDomain = "@" + input_email.value.split("@")[1];
        const abuseCheck = emailAccount.includes("abuse");
        const bounceCheck = emailAccount.includes("bounce");
        const blockCheck = emailAccount.includes("block");
        const spamCheck = emailAccount.includes("spam");
        const swearCheck = emailAccount.includes("fuck");
        const validate = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(input_email.value);


        if (input_email.checkValidity() === false) {
            return false;
        } else if (emailAccount.length === 0 || emailDomain.length === 1 || abuseCheck || bounceCheck || blockCheck || spamCheck || swearCheck || !validate) {
            input_email.setCustomValidity("This email address is invalid.");
            return false;
        } else {
            return true;
        }
    }

    inputChangeHandler = (e) => {
        if (e.target.name === 'email') {
            this.validateEmail();
        }

        this.setState({ isFormHalfEdited: true });
    }

    render() {
        return (
            <React.Fragment>
                <Prompt
                    when={this.state.isFormHalfEdited}
                    message="Are you sure you want to leave? Changes you made might not be saved."
                />
                <div className="card my-2">
                    <form onSubmit={this.saveSettings} method="POST" name="emailForm">
                        <div className="card-header d-flex align-items-center">
                            <div className="flex-1">Email Settings</div>
                            <div className="text-right">
                                {this.state.emailEditing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing} name="emailForm">Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing} name="emailForm">Edit</button>
                                }
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-card">
                                <tbody>
                                    <tr>
                                        <td className="no-border">Email</td>
                                        <td style={{ width: '70%' }}>
                                            {this.state.emailEditing === false ?
                                                this.state.userInfo.email
                                                :
                                                <input
                                                    name="email"
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    defaultValue={this.state.userInfo.email}
                                                    onChange={this.inputChangeHandler}
                                                    required
                                                    ref={this.email}
                                                />
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>

                <div className="card my-2">
                    <form onSubmit={this.saveSettings} method="POST" name="passwordForm">
                        <div className="card-header d-flex align-items-center">
                            <div className="flex-1">Password Settings</div>
                            <div className="text-right">
                                {this.state.passwordEditing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing} name="passwordForm">Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing} name="passwordForm">Edit</button>
                                }
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-card">
                                <tbody>
                                    <tr>
                                        <td>Password</td>

                                        {this.state.passwordEditing === true ? (

                                            <td width="70%" ><label htmlFor="oldPassword">Old password</label>
                                                <input type="password"
                                                    name="oldPassword"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    ref={this.old_password}
                                                    required
                                                    onChange={this.inputChangeHandler}
                                                />
                                                <label htmlFor="password">New password</label>
                                                <input type="password"
                                                    name="password"
                                                    className="form-control form-control-sm"
                                                    ref={this.password2}
                                                    autoComplete="off"
                                                    onChange={e => this.validate(e)}
                                                    required
                                                />
                                                <label htmlFor="confirmPassword">Re-enter new password</label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    ref={this.password3}
                                                    onChange={(e) => this.validate(e)}
                                                    required
                                                />
                                            </td>) :
                                            <td width="70%">*********</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default UserInformation;