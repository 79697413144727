import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import Loading from '../../component/UI/Loading/Loading';

import './Dashboard.css';

class Dashboard extends Component {

    state = {
        accountTotals: {    // Account Totals card
            dayStats: {},
            monthStats: {}
        },
        invoiceStats: {     // Invoices card
            invoices: [],
            invoicesTotal: 0
        },
        accountBalance: 0,
        chartStats: [],     // Trends
        todayStats: {       // Todays's Stats
            stats: [],
            totals: {}
        },
        dataLoaded: false,
        statType: 'leads',  // To keep track of selected statType and statTime to plot a chart
        statTime: 'week'
    }

    componentDidMount() {

        const authToken = localStorage.getItem('mb-auth-token');

        Promise.all([
            fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/accountTotals`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                }
            }),
            fetch(`${process.env.REACT_APP_API_URL}/invoices`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                }
            }),
            fetch(`${process.env.REACT_APP_API_URL}/accountBalance`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                }
            }),
            fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/chart`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                }
            }),
            fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/todayStats`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                }
            })
        ])
            .then(([res1, res2, res3, res4, res5]) => Promise.all([res1.json(), res2.json(), res3.json(), res4.json(), res5.json()]))
            .then(([accountTotalsResult, invoicesResult, accountBalanceResult, chartResult, todayResult]) => {
                if (accountTotalsResult["success"] === true) {
                    this.setState({ accountTotals: { dayStats: accountTotalsResult.dayStats, monthStats: accountTotalsResult.monthStats }, dataLoaded: true });
                }
                if (invoicesResult["success"] === true) {
                    this.setState({ ...this.state, invoiceStats: { invoices: invoicesResult.invoices, invoicesTotal: invoicesResult.total }, dataLoaded: true });
                }
                if (accountBalanceResult["success"] === true){
                    this.setState({...this.state, accountBalance: accountBalanceResult.accountBalance, dataLoaded: true})
                }
                if (chartResult["success"] === true) {
                    this.setState({
                        ...this.state, chartStats: chartResult.stats, dataLoaded: true
                    });
                }
                if (todayResult["success"] === true) {
                    this.setState({ ...this.state, todayStats: { stats: todayResult.stats, totals: todayResult.totals } });
                }
            }).catch(error => this.props.showSystemMessage('error', error.message));
    }

    handleStatTypeChange = (event) => {
        this.setState({ ...this.state, chartStats: [], statType: event.target.value }, () => {
            this.fetchNewChartData();
        });
    }

    handleStateTimeChange = (event) => {
        this.setState({ ...this.state, chartStats: [], statTime: event.target.value }, () => {
            this.fetchNewChartData();
        });
    }

    fetchNewChartData = () => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/chart?type=${this.state.statType}&time=${this.state.statTime}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({ ...this.state, chartStats: result.stats });
                }
            })
            .catch(error => error);
    }

    render() {

        let chartLabels = [];
        let chartData = [];

        // const today = moment('2019-01-01').format('YYYY-MM-DD');
        // const monthStart = moment('2019-01-01').startOf('month').format('YYYY-MM-DD');
        // const monthEnd = moment('2019-01-01').endOf('month').format('YYYY-MM-DD');

        const today = moment().format('YYYY-MM-DD');
        const monthStart = moment().startOf('month').format('YYYY-MM-DD');
        const monthEnd = moment().endOf('month').format('YYYY-MM-DD');

        let statLabelClasses = "btn btn-secondary btn-sm";

        if (this.state.chartStats.length > 0) {
            this.state.chartStats.forEach(stat => {
                chartLabels.push(stat.date);
                chartData.push(stat.value);
            });
        }

        let first5invoices = [];

        if (this.state.invoiceStats.invoices.length > 5) {
            first5invoices = this.state.invoiceStats.invoices.slice(0, 5);
        } else {
            first5invoices = this.state.invoiceStats.invoices;
        }

        return (
            <React.Fragment>
                {this.state.dataLoaded ?
                    <div className="overflow-hidden">
                        <div className="row">
                            <div className="col-md-4 split-left">
                                <div className="card my-2">
                                    <div className="card-header card-header-no-border">Account Totals</div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-card table-header-no-border">
                                                <thead>
                                                    <tr align="right">
                                                        <th scope="col"></th>
                                                        <th scope="col">Clicks</th>
                                                        <th scope="col">Leads</th>
                                                        <th scope="col">Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Today</th>
                                                        <td align="right"><Link to={{ pathname: '/reports/earnings', search: `?startDate=${today}&endDate=${today}` }}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.accountTotals.dayStats.clicks)}</Link></td>
                                                        <td align="right"><Link to={{ pathname: '/reports/conversions', search: `?startDate=${today}&endDate=${today}` }}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.accountTotals.dayStats.leads)}</Link></td>
                                                        <td align="right"><Link to={{ pathname: '/reports/earnings', search: `?startDate=${today}&endDate=${today}` }}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.accountTotals.dayStats.cost)}</Link></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">This Month</th>
                                                        <td align="right"><Link to={{pathname: '/reports/earnings', search:`startDate=${monthStart}&endDate=${monthEnd}`}}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.accountTotals.monthStats.clicks)}</Link></td>
                                                        <td align="right"><Link to={{pathname: '/reports/conversions', search:`startDate=${monthStart}&endDate=${monthEnd}`}}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.accountTotals.monthStats.leads)}</Link></td>
                                                        <td align="right"><Link to={{pathname: '/reports/earnings', search:`startDate=${monthStart}&endDate=${monthEnd}`}}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.accountTotals.monthStats.cost)}</Link></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                { this.state.accountBalance !== 0 &&
                                    <div className="card my-2">
                                        <div className="card-header card-header-no-border">
                                            <div>Account Balance</div>                                            
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-card">
                                                    <tbody>                                                        
                                                        <tr>
                                                            <td>Credit / -Owing</td>
                                                            <td align="right"><Link to="/AccountBalance">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.accountBalance*(-1))}</Link></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="card my-2">
                                    <div className="card-header d-flex justify-content-between card-header-no-border">
                                        <div>Recent Invoices</div>
                                        <div><Link to="/invoices">Show All</Link></div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-card">
                                                <tbody>
                                                    {this.state.invoiceStats.invoices.length === 0 ?
                                                        <tr>
                                                            <td>No invoices found.</td>
                                                        </tr> :
                                                        first5invoices.map(invoice =>
                                                            <tr key={invoice.invoice_id}>
                                                                <th className="invoice-id">
                                                                    {invoice.qb_link ? <a target="_blank" href={invoice.qb_link} rel="noopener noreferrer">Invoice #${invoice.invoice_id}</a> : `Invoice #${invoice.invoice_id}`}
                                                                </th>
                                                                <td>{invoice.status}</td>
                                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(invoice.amount)}</td>
                                                                <td align="right">{moment(invoice.date).format("d MMM YYYY")}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 split-right">
                                <div className="card my-2">
                                    <div className="card-header">
                                        Trends
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="d-flex flex-wrap">
                                            <div className="m-2 btn-group btn-group-toggle" data-toggle="buttons" role="group">
                                                <label className={this.state.statType === 'clicks' ? statLabelClasses + " active" : statLabelClasses}>
                                                    <input type="radio" name="chartoptions" id="option1" className="change-graph-option" autoComplete="off" value="clicks" onClick={this.handleStatTypeChange} /> Clicks
                                            </label>
                                                <label className={this.state.statType === 'leads' ? statLabelClasses + " active" : statLabelClasses}>
                                                    <input type="radio" name="chartoptions" id="option2" className="change-graph-option" autoComplete="off" value="leads" onClick={this.handleStatTypeChange} /> Leads
                                            </label>
                                                <label className={this.state.statType === 'cost' ? statLabelClasses + " active" : statLabelClasses}>
                                                    <input type="radio" name="chartoptions" id="option3" className="change-graph-option" autoComplete="off" value="cost" onClick={this.handleStatTypeChange} /> Cost
                                            </label>
                                            </div>

                                            <div className="my-2">
                                                <select id="quickDate" className="custom-select custom-select-sm mr-2 max-width-150 change-graph-option" onChange={this.handleStateTimeChange}>
                                                    <option value="week">This Week</option>
                                                    <option value="month">This Month</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Plot the chart */}
                                        <article className="canvas-conatainer">
                                            <Line className="chartjs-render-monitor mb-4 w-100"
                                                data={{
                                                    labels: chartLabels,
                                                    datasets: [{
                                                        data: chartData,
                                                        lineTension: .3,
                                                        backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                        borderColor: 'rgba(23, 162, 184, 1)',
                                                        borderWidth: 4,
                                                        pointBackgroundColor: 'rgba(23, 162, 184, 1)'
                                                    }]
                                                }}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    tooltips: {
                                                        mode: 'nearest',
                                                        intersect: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                beginAtZero: true
                                                            }
                                                        }]
                                                    },
                                                    legend: {
                                                        display: false
                                                    }
                                                }}
                                            />
                                        </article>
                                    </div>
                                </div>
                                <div className="card my-2">
                                    <div className="card-header card-header-no-border">Today's Stats</div>
                                    <div className="card-body p-0 mb-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-card">
                                                <thead className="table-header-no-border">
                                                    <tr>
                                                        <th scope="col">Campaign Name</th>
                                                        <th scope="col" className="text-right">Clicks</th>
                                                        <th scope="col" className="text-right">Leads</th>
                                                        <th scope="col" className="text-right">Sales</th>
                                                        <th scope="col" className="text-right">Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.todayStats.stats.length === 0 ?
                                                        <tr>
                                                            <td colSpan="5">No stats have been recorded today.</td>
                                                        </tr> :
                                                        this.state.todayStats.stats.map(stat =>
                                                            <tr key={stat.campaign_id}>
                                                                <td><Link to={`/campaign/${stat.campaign_id}`}>{stat.name}</Link></td>
                                                                <td className="text-right"><Link to={{pathname:'/reports/clicks', search: `oid=${stat.campaign_id}&cid=notset&lpid=notset&startDate=${today}&endDate=${today}`}}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(stat.clicks)}</Link></td>
                                                                <td className="text-right"><Link to={{pathname:'/reports/conversions', search: `oid=${stat.campaign_id}&cid=notset&lpid=notset&startDate=${today}&endDate=${today}`}}>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(stat.leads)}</Link></td>
                                                                <td className="text-right"><Link to={{pathname:'/reports/earnings', search: `oid=${stat.campaign_id}&cid=notset&lpid=notset&startDate=${today}&endDate=${today}`}}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(stat.sales)}</Link></td>
                                                                <td className="text-right"><Link to={{pathname:'/reports/earnings', search: `oid=${stat.campaign_id}&cid=notset&lpid=notset&startDate=${today}&endDate=${today}`}}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(stat.cost)}</Link></td>
                                                            </tr>
                                                        )}
                                                    <tr className="font-weight-bold">
                                                        <td>Totals</td>
                                                        <td align="right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.todayStats.totals.clicks)}</td>
                                                        <td align="right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0 }).format(this.state.todayStats.totals.leads)}</td>
                                                        <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.todayStats.totals.sales)}</td>
                                                        <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.todayStats.totals.cost)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <Loading />
                }
            </React.Fragment>
        );
    }
}

export default Dashboard;