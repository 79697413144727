import React from 'react';

import AccountInformation from './AccountInformation/AccountInformation';
import UserInformation from './UserInformation/UserInformation';

const settings = (props) => {

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-6">
                    <AccountInformation accountInfo={props.advertiser} showSystemMessage={props.showSystemMessage} editName={props.editName} />
                </div>
                <div className="col-lg-6">
                    <UserInformation userInfo={props.users} showSystemMessage={props.showSystemMessage} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default settings;