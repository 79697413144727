import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import { Circle, Home, Layers, BarChart2, DollarSign, Box, MessageCircle } from 'react-feather';
import { Modal } from 'reactstrap';

import ContactModal from '../../../component/ContactModal/ContactModal';
import MBIntegrationModal from './MBIntegrationModal/MBIntegrationModal';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';

import './LeftNavigationBar.css';

class LeftNavigationBar extends Component {

    state = {
        integrationModalOpen: false,
        isContactModalOpen: false,   // Controlling opening and closing of contact modal
        manager: {}                  // state object to store manager details
    }

    componentDidMount() {
        const authtoken = localStorage.getItem('mb-auth-token');

        verifyToken().then(result => {
            if (result === true) {
                return fetch(`${process.env.REACT_APP_API_URL}/advertiser/manager`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({ manager: result.manager });
                        } else if (result["success"] === false) {
                            if (result.code !== 401) {
                                this.props.showSystemMessage('error', result.errors.message);
                            }
                        } else {
                            this.props.showSystemMessage('error', 'There was an internal error while fetching manager details. Please try again later.');
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage('error', 'Internal error');
        });
    }

    toggleContactModal = () => {
        this.setState({ isContactModalOpen: !this.state.isContactModalOpen });
    }

    toggleIntegrationModal = () => {
        this.setState({ integrationModalOpen: !this.state.integrationModalOpen });
    }

    render() {
        return (
            <React.Fragment>
                <nav className="sidebar mb-sidebar">
                    <div className="left-nav-bar">
                        <h6 className="sidebar-header">contact</h6>

                        <div className="d-flex">
                            <button className="btn btn-sm btn-link leftbar-btn ml-2 pt-0 mr-0 pr-0" style={{ fontWeight: "500" }}>
                                <Circle size={15} />
                            </button>
                            <span onClick={this.toggleContactModal}>
                                <button className="btn btn-sm btn-link leftbar-btn" style={{ fontWeight: "500" }}>
                                    {this.state.manager.name}
                                </button>
                                <Modal isOpen={this.state.isContactModalOpen} toggle={this.toggleContactModal} className="modal-sm">
                                    <ContactModal manager={this.state.manager} />
                                </Modal>
                            </span>
                        </div>

                        <h6 className="sidebar-header">navigation</h6>
                        <div className="link-title">
                            <NavLink to="/dashboard" className="nav-link" activeClassName="active-nav">
                                <Home size={16} />
                            </NavLink>
                            <span>
                                <NavLink to="/dashboard" className="nav-link" activeClassName="active-nav">
                                    Dashboard
                                </NavLink>
                            </span>
                        </div>

                        <div className="link-title">
                            <NavLink to="/campaigns" className="nav-link" activeClassName="active-nav">
                                <Layers size={16} />
                            </NavLink>
                            <span>
                                <NavLink to="/campaigns" className="nav-link" activeClassName="active-nav">
                                    Campaigns
                                </NavLink>
                            </span>
                        </div>

                        <div className="link-title">
                            <NavLink to="/reports" className="nav-link" activeClassName="active-nav">
                                <BarChart2 size={16} />
                            </NavLink>
                            <span>
                                <NavLink to="/reports" className="nav-link" activeClassName="active-nav">
                                    Reports
                                </NavLink>
                            </span>
                        </div>

                        <div className="link-title">
                            <NavLink to="/invoices" className="nav-link" activeClassName="active-nav">
                                <DollarSign size={16} />
                            </NavLink>
                            <span>
                                <NavLink to="/invoices" className="nav-link" activeClassName="active-nav">
                                    Invoices
                                </NavLink>
                            </span>
                        </div>

                        <div className="d-flex">
                            <button className="btn btn-sm btn-link leftbar-btn ml-2 pt-0 mr-0 pr-0" style={{ fontWeight: "500" }}>
                                <Box size={15} />
                            </button>
                            <span onClick={this.toggleIntegrationModal}>
                                <button className="btn btn-sm btn-link leftbar-btn" style={{ fontWeight: "500", paddingLeft: "14px" }}>
                                    Integration
                                </button>
                                <Modal isOpen={this.state.integrationModalOpen} toggle={this.toggleIntegrationModal} className="modal-md">
                                    <MBIntegrationModal modalClosed={this.toggleIntegrationModal}/>
                                </Modal>
                            </span>
                        </div>

                        <div className="link-title">
                            <a href="https://blogmaxbounty.com" className="nav-link">
                                <MessageCircle size={16} />
                            </a>
                            <span>
                                <a href="https://blogmaxbounty.com" className="nav-link" target="_blank" rel="noopener noreferrer">
                                    Blog
                                </a>
                            </span>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }
}

export default LeftNavigationBar;