import React, { Component } from 'react';

import CountryList from '../../../data/countrylist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'reactstrap';

import './CampaignInfo.css';

class CampaignInfo extends Component {

    state = {
        expandCountries: false,
        openDescriptionModal: false
    }

    getCountryList = () => {
        // Get the entire list of countries from the endpoint
        // Also need to determine if we're on the last country in the array, if we are, we don't put a comma
        let list = '';
        let arrayLen = this.props.data.allowed_countries.length;
        let count = 0;
        let separator = ', ';
        this.props.data.allowed_countries.map(country_code => {
            count = count + 1;
            if (count === arrayLen) {
                separator = '';
            }
            return CountryList.countries.filter(country => (country.code === country_code))[0] &&
                (list = list + CountryList.countries.filter(country => (country.code === country_code))[0].name + separator);
        });
        return list;
    }

    expandCountries = () => {
        if (this._isMounted) {
            this.setState({
                expandCountries: !this.state.expandCountries
            });
        }
    }

    getOSlist = () => {

        const { details, os_list } = this.props.data;
        let rows = [];

        if (details.os_filtering === 'Y') {
            os_list.map(os => {
                let displayedOS;
                if (os === 'iOS') {
                    displayedOS = <FontAwesomeIcon icon={['fab', 'apple']} />;
                } else if (os === 'Android') {
                    displayedOS = 'Android';
                }
                return rows.push(<React.Fragment key={os}>{displayedOS} {os}</React.Fragment>)
            });
        } else {
            if (details.desktop_traffic !== 'Y') {
                rows = 'All mobile operating system allowed';
            } else {
                rows = 'All device operating system allowed';
            }
        }
        return rows;
    }

    toggleDescriptionModal = () => {
        this.setState({ openDescriptionModal: !this.state.openDescriptionModal });
    }

    render() {

        const { allowed_countries, details } = this.props.data;

        // The classes for the country section will change depending on whether it's expanded or not.
        let countryClasses = 'position-relative country-container';
        let countryOverlayClasses = 'position-absolute text-center country-overlay';
        let countryCountIsBig = 5;

        if (this.state.expandCountries) {
            countryClasses = 'position-relative country-container open';
            countryOverlayClasses = 'position-absolute text-center country-overlay open';
        } else {
            if (allowed_countries.length > countryCountIsBig) {
                countryClasses = 'position-relative country-container closed';
            } else {
                countryClasses = 'position-relative country-container closed short';
            }
            countryOverlayClasses = 'position-absolute text-center country-overlay closed';
        }

        return (
            <React.Fragment>
                <Modal isOpen={this.state.openDescriptionModal} toggle={this.toggleDescriptionModal} className="modal-lg">
                    <div className="modal-body">
                        <h5 className="mb-3 pb-3 border-bottom font-weight-bold text-center">{this.props.data.details.name}</h5>
                        <p dangerouslySetInnerHTML={{ __html: this.props.data.details.description }} style={{ fontSize: "14px" }} />
                    </div>
                </Modal>
                <div className="card my-2">
                    <div className="card-header">
                        Campaign Information
                    </div>
                    <div className="card-body p-0">
                        <div className="campaign-image border-bottom">
                            <img className="w-100"
                                src={`${details.thumbnail}`} alt="Landing Page Screenshot" />
                        </div>
                        <div className="d-flex justify-content-between p-2">
                            <div className="font-weight-bold">{details.name}</div>
                            <div>
                                <button className="btn btn-sm btn-link pt-0" onClick={this.toggleDescriptionModal}>Description</button>
                            </div>
                        </div>

                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-card">
                                    <tbody>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                {details.status === 'Cancelled' ?
                                                    <span className="text-danger">{details.status}</span>
                                                :
                                                    <span className="text-info">{details.status}</span>
                                                }                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Countries Allowed</td>
                                            <td>
                                                <div className={countryClasses}>
                                                    {allowed_countries.length > countryCountIsBig && (
                                                        <div className={countryOverlayClasses}>
                                                            <button className="btn btn-link btn-sm" onClick={this.expandCountries}>
                                                                {this.state.expandCountries ? <span>See Less</span> : <span>See More</span>}
                                                            </button>
                                                        </div>
                                                    )}
                                                    {details.geo_filtering === 'Y' ?
                                                        this.getCountryList()
                                                        :
                                                        <React.Fragment>Traffic allowed from all countries</React.Fragment>
                                                    }
                                                </div>
                                            </td>{/* props.allowedCountries*/}
                                        </tr>
                                        <tr>
                                            <td>Devices</td>
                                            <td> {this.getOSlist()} </td>
                                        </tr>
                                        <tr>
                                            <td>Expiry Date</td>
                                            <td> {details.expiry_date} </td>
                                        </tr>
                                        <tr>
                                            <td>Tracking Type</td>
                                            <td>
                                                {(details.tracking_type === 'S' || details.tracking_type === 'G') ?
                                                    <span>Server-to-server tracking</span>
                                                : details.tracking_type === 'L' ?
                                                    <span>Pixel tracking</span>
                                                : details.tracking_type === 'M' ?
                                                    <span>MaxBounty Tag</span>
                                                : details.tracking_type === 'I'?
                                                    <span>iFrame tracking</span>
                                                : details.tracking_type === 'A' ?
                                                    <span>API tracking</span>
                                                :
                                                    <span>Unknown</span>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default CampaignInfo;