import React, { useState, useEffect } from 'react';
import { Copy, ChevronRight, ChevronDown } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './IntegrationMBTag.css';

const IntegrationMBTag = (props) => {

    let [rateNameInfo, updateRateNameInfo] = useState(false);
    let [merchantDataInfo, updateMerchantDataInfo] = useState(false);
    let [prepopInfo, updatePrepopInfo] = useState(false);
    let [saleAmountInfo, updateSaleAmountInfo] = useState(false);
    let [testingInfo, updateTestingInfo] = useState(false);
    let [exampleInfo, updateExampleInfo] = useState(false);

    let pixel =`
<script>
    !function(e,t,c,n,l,m){e.mbc||((n=e.mbc=function(){n.callMethod?n.callMethod.apply(n,[arguments]):n.queue.push(arguments)}).loaded=!0,n.queue=[],(l=t.createElement(c)).src="https://mb1-cdn.com/lib/mb-lib.min.js",l.async=!0,(m=t.getElementsByTagName(c)[0]).parentNode.insertBefore(l,m))}(window,document,"script");
    
    mbc('init',${props.advertiserId})
</script>`

    let functionCall = 
`<script>
    function trackConversion(){
        mbc('track')
    }
</script>
<button onClick='trackConversion()'>Submit</button>
`

    return ( 
        <div className="integration-modal">
            <div className="modal-header">
                <h5 className="font-weight-bold">Your MaxBounty Tag</h5>
            </div>
            <div className="modal-body">
                <div className="mb-3">Add the following code to the <strong>&lt;head&gt;</strong> section of your website's HTML. This is your MB Tag. Please note that it must be on every page of your site.</div>
                <div className="alert alert-light border">
                    <div className="integrationMBTag-copy">
                        <CopyToClipboard text={pixel}>
                            <button id="tracking-ready" className="btn btn-outline-primary btn-sm">
                                <Copy size={16} /><span style={{ paddingLeft: "5px", paddingTop: "50px" }}>Copy</span>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>{pixel}</pre>                    
                </div>
                <div className="mb-3">
                    To track a lead, you can simply call our <strong>track</strong> event by placing your postback code in the <strong>&lt;head&gt;</strong> section of your page where the conversion should occur. The postback code must be below your MB Tag.
                    <br></br><br></br>
                    The following are EXAMPLES ONLY. To get your custom postback code, click the "Get Code" link for the appropriate rate.
                </div>
                <span className="text-secondary">This will trigger a lead upon page load.</span>
                <div className="alert alert-light border">                    
                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track')&lt;/script&gt;</pre>  
                </div>
                <div className="mb-3">
                    You can also track a lead dynamically, for example with the click of a button.
                </div>                
                <span className="text-secondary">This will trigger a lead upon a button click.</span>
                <div className="alert alert-light border">                    
                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>{functionCall}</pre>  
                </div>
                <div className="mb-3">
                    Your conversions will now begin tracking. Please reach out to your advertiser manager to test your integration.<br></br><br></br>
                    The MB Tag has several advanced features that are simple to implement, click on a topic below to learn more.
                </div>

                <section>

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updateRateNameInfo(!rateNameInfo)}>
                            {rateNameInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Tracking for multiple rates
                        </button>
                        { rateNameInfo &&
                            <div className="mx-3">
                                <div className="mb-3">                            
                                    The <strong>track</strong> event has an optional second argument that can receive various pieces of information. The argument must be a javascript object, and it can receive several parameters. If you are sending multiple parameters, they should be separated with a comma.<br></br><br></br>
                                    For example, if you have multiple rates, you can specify that a conversion will be paying one or the other with the <strong>mbr</strong> parameter, and setting it to one of your rate names.
                                </div>
                                <div className="alert alert-light border">
                                    {props.rates.length < 1 ?                                        
                                        <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track', &#123;mbr:'example_rate'&#125;)&lt;/script&gt;</pre>
                                         :
                                        props.rates.map((rate, i) => (rate.status === 'Active' &&
                                            <div className="mb-2">
                                                <div>{rate.description}</div>
                                                <pre key={i} className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>    &lt;script&gt;mbc('track', &#123;mbr:'{rate.name ? rate.name : 'example_rate'}'&#125;)&lt;/script&gt;</pre>
                                            </div>
                                        ))
                                    }                                    
                                </div>
                                <div className="mb-3">
                                    To ensure conversions pay the appropriate payout, please use the "Get Code" buttons beside your rates to copy your postback code.
                                </div>
                            </div>
                        }
                    </div>

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updateSaleAmountInfo(!saleAmountInfo)}>
                            {saleAmountInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Sale amounts for revshare rates
                        </button>
                        { saleAmountInfo &&
                            <div className="mx-3">
                                <div className="mb-3">                            
                                    The <strong>track</strong> event has an optional second argument that can receive various pieces of information. The argument must be a javascript object, and it can receive several parameters. If you are sending multiple parameters, they should be separated with a comma.<br></br><br></br>
                                    For example, if your rate pays a percentage of sale, you can pass the sale amount using the <strong>mbs</strong> parameter. In the example below, a sale amount of $100 is being passed. Make sure to only include numeric values.
                                </div>
                                <div className="alert alert-light border">
                                    {
                                        props.rates.findIndex(rate => rate.rate_type === 'Revshare') >= 0 ?
                                            <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track', &#123;mbr:'{props.rates.find(rate => rate.rate_type === 'Revshare').name}', mbs:'100'&#125;)&lt;/script&gt;</pre>
                                        :
                                            <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track', &#123;mbs:100&#125;)&lt;/script&gt;</pre>
                                    }
                                </div>                                
                            </div>
                        }
                    </div>                    

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updateMerchantDataInfo(!merchantDataInfo)}>
                            {merchantDataInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Attributing conversions to your internal order IDs
                        </button>
                        { merchantDataInfo &&
                            <div className="mx-3">
                                <div className="mb-3">                            
                                    The <strong>track</strong> event has an optional second argument that can receive various pieces of information. The argument must be a javascript object, and it can receive several parameters. If you are sending multiple parameters, they should be separated with a comma.<br></br><br></br>
                                    For example, if the conversion in your system creates an order ID of 123456, you can specify that a conversion came from this ID with the <strong>mbd</strong> parameter.
                                </div>
                                <div className="mb-3">                            
                                    You can pass MaxBounty an ID from your system along with each conversion. This will attribute a conversion to a particular order ID from your system, which can help identify the source of conversions.
                                </div>                                
                                <div className="alert alert-light border">
                                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track', &#123;mbd:'123456'&#125;)&lt;/script&gt;</pre>
                                </div>
                                <div className="mb-3">
                                    This will allow us to pinpoint exactly which conversion relates to a particular order from your system.
                                </div>
                            </div>
                        }
                    </div>

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updateExampleInfo(!exampleInfo)}>
                            {exampleInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Example of a "track" event with multiple parameters
                        </button>
                        { exampleInfo &&
                            <div className="mx-3">
                                <div className="mb-3">
                                    The following is an example of the track event being called with three parameters in the second argument: a rate called "payout", an order ID of "123456", and a sale amount of $100.
                                </div>                                
                                <div className="alert alert-light border">
                                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;script&gt;mbc('track', &#123;mbr:'payout', mbd:'123456', mbs:100&#125;)&lt;/script&gt;</pre>
                                </div>                                
                            </div>
                        }
                    </div>

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updatePrepopInfo(!prepopInfo)}>
                            {prepopInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Pre-populating user data (prepop)
                        </button>
                        { prepopInfo &&
                            <div className="mx-3">
                                <div className="mb-3">                            
                                    The MB Tag has built-in functionality to enable prepop on your landing page forms. You can simply add the <strong>mb-prepop</strong> attribute to your form fields, and set it to an appropriate name. Usually, the value of the "name" field would be a good choice.<br></br><br></br>
                                    For example, if you have a field for a user's first name, you can add prepop functionality like so:
                                </div>
                                <div className="alert alert-light border">
                                    <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>&lt;input type="text" name="first_name" mb-prepop="first_name"/&gt;</pre>
                                </div>
                                <div className="mb-3">
                                    Once you have set up <strong>mb-prepop</strong> attributes on your fields, please let your advertiser manager know their values, and they will update your campaign so that our affiliates will know what data to forward to your landing page.                                    
                                </div>
                            </div>
                        }
                    </div>

                    <div className="border-top">
                        <button className="btn btn-link" onClick={() => updateTestingInfo(!testingInfo)}>
                            {testingInfo ? <ChevronDown size={16} /> : <ChevronRight size={16} />} Testing
                        </button>
                        { testingInfo &&
                            <div className="mx-3">
                                <div className="mb-3">                            
                                    Your advertiser manager will be in contact with you once your campaign is ready to start testing. Here are your testing links:
                                </div>
                                <div className="alert alert-light border">
                                    {props.landingPages.length < 1 ?                                        
                                        <pre className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>No landing pages have been created. Please ask your advertiser manager to help you create one.</pre>
                                         :
                                        props.landingPages.map((lp, i) => (
                                            <div className="mb-2">
                                                <div>{lp.name} {lp.default_lp === 'Y' && '(Default)'}</div>
                                                <pre key={i} className="p-0 m-0" style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>    {lp.test_link}</pre>
                                            </div>
                                        ))
                                    }        
                                </div>                                
                            </div>
                        }
                    </div>                    

                </section>                
                
            </div>
        </div>

    );
}
 
export default IntegrationMBTag;