import React from 'react';

import { CheckCircle } from 'react-feather';

const landingPages = (props) => (
    <div className="card my-2">
        <div className="card-header">Landing Pages</div>
        <div className="card-body p-0">
            <div className="table-responsive">
                <table className="table table-striped">
                    <tbody>
                        {props.landingPages.length === 0 ?
                            <tr>
                                <td>No landing pages found.</td>
                            </tr> :
                            props.landingPages.map(lPage => (
                                <tr key={lPage.landing_page_id}>
                                    <td className="pt-3"> {lPage.default_flag === 'Y' ? <CheckCircle size={20} style={{ color: "#17a2b8" }} className="pr-1" /> : null}
                                        {lPage.name}
                                    </td>
                                    <td>
                                        <input className="form-control form-control-sm" type="text" readOnly value={lPage.landing_url} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

export default landingPages;