import React, { Component } from 'react';
import ReportDropdown from '../ReportDropdown/ReportDropdown';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';
import Loading from '../../../component/UI/Loading/Loading';
import { clearLocalStorageAndReload, verifyToken } from '../../../assets/JS/functions';

import moment from 'moment';
import _ from 'lodash';

class ReportEarnings extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        report: [],
        campaign: [],
        totals: {},
        sort: {
            column: 'earnings',
            order: 'desc'
        }
    }

    componentDidMount() {

        this._isMounted = true;

        this.setState({
            dataLoaded: false
        });

        const mbToken = localStorage.getItem('mb-auth-token');
        
        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/earnings?startDate=${this.props.startDate}&endDate=${this.props.endDate}`;

        if (this.props.offerId > 0){
            fetchUrl = fetchUrl + `&oid=${this.props.offerId}`;
        }

        if (this.props.affiliateId > 0){
            fetchUrl = fetchUrl + `&aid=${this.props.affiliateId}`;
        }

        /* Get the affiliate object from the DB and populate the state information */
        verifyToken().then(result => {
            if (result === true) {
                return fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                }).then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                this.props.handleDownloadData(result);

                                if (this._isMounted) {
                                    this.setState({
                                        dataLoaded: true,
                                        report: result.report,
                                        totals: result.totals,
                                        campaign: result.campaign
                                    });
                                }
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportEarnings: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    handleSort = (sortBy) => {
        const sort = { ...this.state.sort };

        if (sort.column === sortBy) {
            sort.order = (sort.order === 'asc') ? 'desc' : 'asc';
        } else {
            sort.column = sortBy;
            switch (sort.column) {
                case 'name':
                    sort.order = 'asc'
                    break;
                case 'clicks':
                    sort.order = 'desc'
                    break;
                case 'leads':
                    sort.order = 'desc'
                    break;
                case 'conversion':
                    sort.order = 'desc'
                    break;
                case 'sales':
                    sort.order = 'desc'
                    break;
                case 'cost':
                    sort.order = 'desc'
                    break;
                case 'epc':
                    sort.order = 'desc'
                    break;
                default:
                    throw new Error("Invalid sorting method");
            }

        }

        const sorted = _.orderBy(this.state.report, sortBy, sort.order)
        if (this._isMounted) {
            this.setState({
                report: sorted,
                sort: sort
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        let campaignId = this.state.campaign ? this.state.campaign.campaign_id : 0;

        return (
            <React.Fragment>

                {this.state.dataLoaded && this.state.campaign &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Campaign Summary Report for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link> &nbsp;
                        <Link to={this.props.getCancelUrl('earnings', 'offer', 0, 0, 0, 0, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.affiliateId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Affiliate ID: {this.props.affiliateId} &nbsp;
                        <Link to={this.props.getCancelUrl('earnings', 'affiliate', campaignId, 0, this.props.creativeId, this.props.landingPageId, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }
            
                <table className="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#ID</th>
                            <ReportHeader sortBy="name" title="Campaign" sort={this.state.sort} handleSort={this.handleSort} />
                            <ReportHeader sortBy="clicks" title="Clicks" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="leads" title="Leads" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="conversion" title="CR" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="epc" title="EPC" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="sales" title="Sales" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />
                            <ReportHeader sortBy="cost" title="Cost" sort={this.state.sort} alignment="right" handleSort={this.handleSort} />                        
                            <th scope="col" className="text-right">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colSpan="10">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>

                                        {this.state.report.map(item => (
                                            <tr key={item.campaign_id}>
                                                <td>{item.campaign_id}</td>
                                                <td><Link to={`/campaign/${item.campaign_id}`}>{item.name}</Link></td>
                                                <td align="right"><Link to={this.props.getReportLink('clicks', item.campaign_id, this.props.affiliateId, 0, 0, formattedStartDate, formattedEndDate)}>{Intl.NumberFormat('en-GB').format(item.clicks)}</Link></td>
                                                <td align="right"><Link to={this.props.getReportLink('conversions', item.campaign_id, this.props.affiliateId, 0, 0, formattedStartDate, formattedEndDate)}>{Intl.NumberFormat('en-GB').format(item.leads)}</Link></td>
                                                <td align="right">{parseFloat(item.conversion*100).toFixed(2)}%</td>
                                                <td align="right">${parseFloat(item.epc).toFixed(2)}</td>
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.cost)}</td>                                            
                                                <td className="pr-2"><ReportDropdown offerId={item.campaign_id} affiliateId={this.props.affiliateId ? this.props.affiliateId : 0} creativeId={0} landingPageId={0} startDate={formattedStartDate} endDate={formattedEndDate} getReportLink={this.props.getReportLink} /></td>
                                            </tr>
                                        ))}

                                        <tr className="font-weight-bold">
                                            <td colSpan="2">Totals</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.clicks)}</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.leads)}</td>
                                            <td align="right">{parseFloat(this.state.totals.conversion*100).toFixed(2)}%</td>
                                            <td align="right">${parseFloat(this.state.totals.epc).toFixed(2)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.sales)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.cost)}</td>                                        
                                            <td align="right">&nbsp;</td>
                                        </tr>

                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colSpan="10"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default ReportEarnings;