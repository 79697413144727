import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

class MostRecentLeads extends Component {

    state = {
        recentLeads: [],
        dataLoaded: false
    }

    componentDidMount() {
        this.getMostRecentLeads();
    }

    getMostRecentLeads = () => {
        const authToken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/recentleads/${this.props.campaignId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({ recentLeads: result.recent_leads, dataLoaded: true });
                } else if (result["success"] === false) {
                    this.setState({ dataLoaded: true });
                } else {
                    this.props.showSystemMessage('error', 'An internal error occurred while fetching recent leads.');
                    this.setState({ dataLoaded: true });
                }
            })
            .catch(error => {
                this.props.showSystemMessage('error', error.message);
            });
    }

    getStatus = (statusLetter, reverseDate) => {
        switch (statusLetter) {
            case 'P':
                return 'Payable';
            case 'W':
                return 'Waiting';
            case 'X':
                return 'Cancelled';
            case 'Z':
                return 'Processing';
            case 'R':
                reverseDate = moment(reverseDate).format('YYYY/MM/DD');
                return `Reversed on ${reverseDate}`;
            default:
        }
    }

    render() {
        return (
            <div className="card my-2">
                <div className="card-header d-flex">
                    <div className="mr-auto">Most Recent Leads</div>
                    <div className="report-link">
                        <Link to={{ pathname:'/reports/conversions', search: `?oid=${this.props.campaignId}` }}>Leads Report</Link>
                    </div>
                </div>
                {this.state.dataLoaded &&
                    <div className="card-body p-0">
                        <div className="table-reponsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Date/Time</th>
                                        <th scope="col" className="text-right">Sales</th>
                                        <th scope="col" className="text-right">CPA</th>
                                        <th scope="col" className="text-right">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.recentLeads.length <= 0
                                        ?
                                        <tr>
                                            <td>No recent leads.</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        :
                                        this.state.recentLeads.map(lead =>
                                            <tr key={lead.lead_date}>
                                                <td>{moment(lead.lead_date).format("YYYY/MM/DD HH:mm:ss")}</td>
                                                <td align="right">${lead.amount}</td>
                                                <td align="right">${lead.rate}</td>
                                                <td align="right">{this.getStatus(lead.status, lead.reversal_date)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default MostRecentLeads;