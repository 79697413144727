import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

class TodayStats extends Component {

    state = {
        stats: {},
        dataLoaded: false
    }

    componentDidMount() {

        const authToken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/advertiserStats/todayStats/${this.props.campaignId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
            
                if (result["success"] === true) {
                    this.setState({ stats: result.stats, dataLoaded: true });
                } else if (result["success"] === false) {
                    this.setState({ dataLoaded: true });
                } else {
                    this.setState({ dataLoaded: true });
                    this.props.showSystemMessage('error', 'An internal error occurred while fetching stats.');
                }
            })
            .catch(error => this.props.showSystemMessage('error', error.message));
    }

    render() {
        const today = moment().format('YYYY-MM-DD');
        return (
            <div className="card my-2">
                <div className="card-header d-flex">
                    <div className="mr-auto">Today's Stats</div>

                    <div className="report-link"><Link to={{
                        pathname: '/reports/earnings',
                        search: `oid=${this.props.campaignId}&startDate=${today}&endDate=${today}`
                    }}>Detailed Report</Link></div>

                </div>
                {this.state.dataLoaded &&
                    <div className="card-body d-flex flex-wrap">
                        <div className="card p-2 m-2 text-center">
                            <h1>
                                <Link to={{ pathname: '/reports/clicks', search: `oid=${this.props.campaignId}&startDate=${today}&endDate=${today}` }}>{this.state.stats ? this.state.stats.total_clicks : 0}</Link></h1>
                            Clicks
                    </div>
                        <div className="card p-2 m-2 text-center">
                            <h1><Link to={{ pathname: '/reports/conversions', search: `oid=${this.props.campaignId}&startDate=${today}&endDate=${today}` }}>{this.state.stats ? this.state.stats.total_leads : 0}</Link></h1>
                            Leads
                    </div>
                        <div className="card p-2 m-2 text-center">
                            <h1><Link to={{ pathname: '/reports/earnings', search: `oid=${this.props.campaignId}&startDate=${today}&endDate=${today}` }}> $
                            {this.state.stats ? (this.state.stats.total_sales).toFixed(2) : 0}
                            
                            </Link></h1>
                            Sales
                    </div>
                        <div className="card p-2 m-2 text-center">
                            <h1><Link to={{ pathname: '/reports/earnings', search: `oid=${this.props.campaignId}&startDate=${today}&endDate=${today}` }} className="text-info">$
                                {this.state.stats ? (this.state.stats.total_cost).toFixed(2) : 0}</Link></h1>
                            Cost
                    </div>
                    </div>}
            </div>
        );
    }
}

export default TodayStats;