import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from 'crypto-js';
import CryptoAES from 'crypto-js/aes';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';

import './Login.css';

class Login extends Component {

    constructor(props) {
        super(props);
        this.formLogin = React.createRef();
        this.email = React.createRef();
        this.password = React.createRef();
    }

    state = {
        dataLoaded: true
    }

    validate = () => {

        /* Check required fields */
        const input_email = this.email.current;
        const input_password = this.password.current;

        if (input_email.checkValidity() === false || input_password.checkValidity() === false) {
            return false;
        }
        else {
            return true;
        }
    }

    login = (e) => {
        e.preventDefault()
        if (this.validate()) {
            /* This is where we do the login */
            this.setState({
                dataLoaded: false
            });

            const data = {
                "email": e.target.email.value,
                "password": e.target.password.value
            }

            fetch(`${process.env.REACT_APP_API_URL}/authentication`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result["success"] === true) {

                            /* Set the localStorage for authentication and authorization */
                            const token = result["mb-app-token"];
                            const t = CryptoAES.encrypt(result["mb-app-token"], CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();

                            localStorage.setItem('mb-auth-token', token);
                            localStorage.setItem('mb-t', t);

                            window.location.reload();
                        } else {
                            // if (result["allow_access"] === false) {
                            //     window.location.replace('https://maxbounty.com/');
                            // } else if (result["allow_access"] === true) {
                            //     this.props.showSystemMessage("error", result.errors.message);
                            // }
                            this.props.showSystemMessage("error", result.errors.message);
                        }

                        this.setState({
                            dataLoaded: true
                        });
                    },
                    (error) => {
                        this.props.showSystemMessage("error", "Connection error.");
                        this.setState({
                            dataLoaded: true
                        });
                    }
                )

        } else {
            /* Forces html5 form validation */
            const formLogin = this.formLogin.current;
            formLogin.reportValidity();
        }
    }

    render() {
        return (
            <React.Fragment>

                <section className="login-container text-center d-flex align-items-center" style={{ background: 'RGBA(0,0,0,0.9)' }}>

                    {/* Background Video */}
                    <div style={{ backgroundImage: `URL(${dotPattern})`, backgroundRepeat: 'repeat', height: '100%', width: '100%', position: 'absolute', zIndex: '9' }}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4" />
                    </video>

                    <form
                        onSubmit={this.login}
                        className="form-signin"
                        ref={this.formLogin}>
                        <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="MaxBounty" width="100" />
                        <p className="font-italic text-white pb-2">Advertiser Login</p>
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <input
                            ref={this.email}
                            name="email"
                            id="inputEmail"
                            className="form-control"
                            placeholder="Email address"
                            autoFocus=""
                            type="email"
                            required />
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input
                            ref={this.password}
                            name="password"
                            id="inputPassword"
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            required />
                        {this.state.dataLoaded ?
                            <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                            :
                            <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        }

                        <p className="mt-5 mb-3 text-muted">2023 - MaxBounty Inc. </p>
                    </form>

                </section>

            </React.Fragment>
        );
    }
}

export default Login;