import React from 'react';

import { Phone, Mail } from 'react-feather';
import { FaSkype } from 'react-icons/fa';

const contactModal = (props) => (
    <div className="contact-modal">
        <div className="modal-body text-center">
            <h5 className="mb-3 pb-3 border-bottom font-weight-bold">{props.manager.name}</h5>
            <p><Phone size={22} className="pr-2" />{props.manager.phone}</p>
            <p><a href={`mailto:${props.manager.e_mail}`} ><Mail size={22} className="pr-2" />{props.manager.e_mail}</a></p>
            <p><FaSkype size={22} className="pr-2" />{props.manager.im_handle}</p>
        </div>
    </div>
);

export default contactModal;