import React from 'react';

import integration2 from '../../../../assets/images/integration-2.png';
import integration3 from '../../../../assets/images/integration-3.png';

import './IntegrationModal.css';

const integrationModal = (props) => {

    return (
        <div className="integration-modal">
            <div className="modal-header">
                <h5 className="font-weight-bold">Using your MaxBounty Postback</h5>
            </div>
            <div className="modal-body">
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 1</h5>
                    <p className="my-3">When a potential customer clicks on one of our affiliate links,
                    they are sent to your landing page along with some additional information int the URL. The key
                piece of the information is the <strong>clickID</strong>.</p>
                    <div className="text-center py-3">
                        <div style={{ borderRadius: "50px", background: "RGBA(0,0,0,0.05)", padding: "5px 10px", margin: "auto", display: "inline-block", color: "RGBA(0,0,0,0.6)" }}>{"http://www.your-landing-page.com?"}<strong>{"clickid={clickID}"}</strong>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 2</h5>
                    <p className="my-3">As the customer travels trhough your sales funnel, you must keep track of this <strong>clickID</strong>. Ask your developer to extract this information from the URL parameter and pass is along to subsequent pages. This can be done via URL parameters, cookies, or HTML5 local storage</p>
                    <div className="text-center py-3 m-auto max-width-750">
                        <img className="w-100" src={integration2} alt="integration-2"/>
                    </div>
                </div>
                <hr />
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 3</h5>
                    <p className="my-3">When your customer arrives on your "Thank You" page, they've reached the end of the conversion process. It is at this point that you need to grab the <strong>clickID</strong>, place it in the postback URL, and inform us of the conversion.</p>
                    <div className="text-center py-3">
                        <img className="max-width-750" src={integration3} alt="integration-3"/>
                    </div>
                    <p className="my-3">Here is the anatomy of your postback URL:</p>
                    
                    <div className="rounded" style={{ overflow: "hidden" }}>
                        <table className="table table-bordered mb-bg-white">
                            <thead>
                                <tr>
                                    <th scope="col">Parameter</th>
                                    <th scope="col">What to pass to MaxBounty</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">mbr</td>
                                    <td>The rate name for the payout (optional, but should be used if you have multiple rates)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">mbd</td>
                                    <td>An identifier from your system, such as a lead_id, order_id, etc. (optional)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">mbi</td>
                                    <td>The <strong>clickID</strong> we originally sent you (required)</td>
                                </tr>
                                <tr>
                                    <td className="text-center">mbs</td>
                                    <td>The sale amount (required for revshare rates)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                        
                    

                    <p className="my-3">The "mbd" and "mbs" parameters should be filled with data from your system, while the "mbi" parameter must be the same <strong>clickID</strong> we originally sent you. Your final postback URL may look similar to this:</p>
                    <div className="text-center py-3">
                        <div style={{ background: "RGBA(0,0,0,0.05)", padding: "5px 10px", margin: "auto", display: "inline-block", color: "RGBA(0,0,0,0.6)" }}>https:/www.{props.trackingDomain}.com/trk/lead?mbr=over_thirty&mbd=16hh8462h13&mbi=1902191751&mbs=25.00</div>
                    </div>
                </div>

                <hr />
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 4</h5>
                    <p className="my-3">The final part of the process is actually making a request to the postback URL. Currently, there are 3 ways of doing so. Your advertiser manager can help you choose the best method for your needs.</p>
                    <p className="font-weight-bold text-danger">**The following are just examples. Use the "Get Code" link to get your customized code.**</p>

                    <div className="py-3 ml-5">
                        <div className="d-flex">
                            <div><div className="numberCircle">1</div></div>
                            <div className="ml-2">
                                <p>
                                    <strong>Image Pixel</strong><br />
                                    Place an image pixel somewhere on your thank you page.
                                    This will notify us that the conversion is complete.
                                </p>
                                <div className="py-1">
                                    <div style={{ background: "RGBA(0,0,0,0.05)", padding: "5px 10px", margin: "auto", display: "inline-block", color: "RGBA(0,0,0,0.6)" }}>&lt;img src={`https://www.${props.trackingDomain}.com/trk/lead?mbr=18&mbd=137&mbi=1902191751&mbs=25.00`} width=0 height=0&gt;</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="py-3 ml-5">
                        <div className="d-flex">
                            <div><div className="numberCircle">2</div></div>
                            <div className="ml-2">
                                <p>
                                    <strong>iFrame</strong><br />
                                    Load the postback URL in an iFrame on your "Thank You" page.
                                </p>
                                <div className="py-1">
                                    <div style={{ background: "RGBA(0,0,0,0.05)", padding: "5px 10px", margin: "auto", display: "inline-block", color: "RGBA(0,0,0,0.6)" }}>&lt;iframe src={`"https://www.${props.trackingDomain}.com/trk/lead?mbr=18&mbd=137&mbi=1902191751&mbs=25.00"`} width=0 height=0 frameborder=0&gt;&lt;/iframe&gt;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="py-3 ml-5">
                        <div className="d-flex">
                            <div><div className="numberCircle">2</div></div>
                            <div className="ml-2">
                                <p>
                                    <strong>Server</strong><br />
                                    Store the postback URL in your database and make HTTP requests in a batch process at a later time.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default integrationModal;