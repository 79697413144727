import React, { Component } from 'react';
import { Modal } from 'reactstrap';

class Creatives extends Component {

    state = {
        showCreativeModal: false,
        selectedCreativeThumbnail: ''
    }

    toggleCreativeModal = (thumbnail) => {
        this.setState({ showCreativeModal: !this.state.showCreativeModal, selectedCreativeThumbnail: thumbnail });
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showCreativeModal} toggle={this.toggleCreativeModal} className="modal-sm">
                    <div className="modal-body">
                        <img src={this.state.selectedCreativeThumbnail} style={{ width: "100%" }} alt="creative thumbnail"/>
                    </div>
                </Modal>
                <div className="card my-2">
                    <div className="card-header">Creatives</div>
                    <div className="card-body p-0 max-height-250">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    {this.props.creatives.length === 0 ? <td>No creatives.</td> :
                                        this.props.creatives.map(creative =>
                                            <tr key={creative.creative_id}>
                                                <td className="text-capitalize">{creative.type}_{creative.creative_id} ({creative.width}x{creative.height})
                                                </td>
                                                <td align="right">
                                                    <button className="btn btn-sm btn-link" value={creative.thumbnail} onClick={() => this.toggleCreativeModal(creative.thumbnail)}>Banner</button>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Creatives;