import React, { Component } from 'react';
import { Filter, ChevronDown } from 'react-feather';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import Loading from '../../component/UI/Loading/Loading';

class Campaigns extends Component {

    state = {
        dataLoaded: false,
        dropdownOpen: false,
        filter: "All"
    }

    componentDidMount(){

        const mbToken = localStorage.getItem('mb-auth-token');  
        
        fetch(`${process.env.REACT_APP_API_URL}/campaigns`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {

                        let filteredCampaigns = _.filter(result.campaigns, function(campaign){
                            return campaign.status !== 'Cancelled'
                        })

                        this.setState({
                            dataLoaded: true,
                            campaigns: result.campaigns,
                            filteredCampaigns: filteredCampaigns
                        });
                    } else if (result["success"] === false) {
                        this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                })
            .catch(error => {
                console.log('Error in Reports: ', error);
                this.props.showSystemMessage("error", "Connection error.");
            });
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleFilter = (filter) => {

        let filteredCampaigns = this.state.campaigns;

        if (filter !== "All"){
            filteredCampaigns = _.filter(filteredCampaigns, function(campaign){
                return campaign.status === filter
            })
        } else {
            filteredCampaigns = _.filter(filteredCampaigns, function(campaign){
                return campaign.status !== 'Cancelled'
            })
        }

        this.setState({
            filter: filter,
            filteredCampaigns: filteredCampaigns
        })
    }

    render() { 
        return (
            <React.Fragment>
            {this.state.dataLoaded ?
                <div className="py-2">                
                    <div className="card">
                        <div className="card-header d-flex align-items-center card-header-no-border">
                            <div className="flex-1">Campaigns</div>
                            <div className="position-relative">
                                <Dropdown className="dropdown" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} size="sm">
                                    <DropdownToggle color="primary">
                                        <Filter size={20} /> {this.state.filter} <ChevronDown size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => {this.toggleFilter("All")}}>All</DropdownItem>
                                        <DropdownItem onClick={() => {this.toggleFilter("Active")}}>Active</DropdownItem>
                                        <DropdownItem onClick={() => {this.toggleFilter("Hidden")}}>Hidden</DropdownItem>
                                        <DropdownItem onClick={() => {this.toggleFilter("Testing")}}>Testing</DropdownItem>
                                        <DropdownItem onClick={() => {this.toggleFilter("Cancelled")}}>Cancelled</DropdownItem>
                                        <DropdownItem onClick={() => {this.toggleFilter("By Request")}}>By Request</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="m-0">
                                <table className="table table-striped table-card table-header-no-border">
                                    <thead>
                                        <tr>
                                        <th scope="col">Offer ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.filteredCampaigns.length > 0 ?
                                            this.state.filteredCampaigns.map(campaign => {
                                                return (
                                                    <tr key={campaign.campaign_id}>
                                                        <td>{campaign.campaign_id}</td>
                                                        <td><Link to={`campaign/${campaign.campaign_id}`}>{campaign.name}</Link></td>
                                                        <td>{campaign.status}</td> 
                                                    </tr>
                                                )
                                            })
                                        :
                                        <tr>
                                            <td colSpan="3">No campaigns.</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <Loading />
            }
            </React.Fragment>            
        );
    }
}
 
export default Campaigns;