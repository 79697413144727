import React, { Component } from 'react';
import Cookies from 'js-cookie';

class OttLogin extends Component {

    state = {
        _mounted: false,
        token: Cookies.get('ADV-AUTH-TOKEN'),
        t: Cookies.get('ADV-T')
    }

    componentDidMount() {
        if (Cookies.get('ADV-AUTH-TOKEN') !== '' &&  Cookies.get('ADV-T') !== '') {
            localStorage.setItem('mb-auth-token', Cookies.get('ADV-AUTH-TOKEN'));
            localStorage.setItem('mb-t', Cookies.get('ADV-T'));
            this.setState({
                ...this.state,
                _mounted: true
            })
        }
    }

    refreshApp = () => {
        window.location.href = process.env.REACT_APP_PORTAL_URL;
    }

    render() {
        return (
            this.state._mounted ?
                <div>{this.refreshApp()}</div>
                :
                <div>Logging in</div>
        );
    }

}

export default OttLogin;