import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReportDropdown from '../ReportDropdown/ReportDropdown';
import Loading from '../../../component/UI/Loading/Loading';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';
import { X } from 'react-feather';
import moment from 'moment';
import _ from 'lodash';

class ReportAffiliates extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: {}
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        })

        const mbToken = localStorage.getItem('mb-auth-token');

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/affiliates?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`;

        if (this.props.offerId) {
            fetchUrl = fetchUrl + `&oid=${this.props.offerId}`
        }

        if (this.props.affiliateId){
            fetchUrl = fetchUrl + `&aid=${this.props.affiliateId}`;
        }

        /* Get the affiliate object from the DB and populate the state information */

        verifyToken().then(result => {
            if (result === true) {
                return fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                // Set the parent Reports.js state with the total pages for Pagination.
                                this.props.handleTotalPages(result.total_pages);
                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report,
                                    campaign: result.campaign,
                                    totals: result.totals
                                });
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportAffiliates: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }

    getReportOutput = () => {
        
        let result;
        let filter = this.props.affiliateFilter;
        let match = new RegExp(filter, "g");
        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");
        let campaignId = this.state.campaign ? this.state.campaign.campaign_id : 0;

        if (!this.props.affiliateFilter){
            result =  this.state.report;
        } else {
            result =  _.filter(this.state.report, function(item){
                return item.publisher_id.toString().match(match);
            });
        }

        return result.slice(this.props.startRow, this.props.endRow).map(item => (
            <tr key={item.publisher_id}>                                                
                <td><Link to={`/reports/earnings?aid=${item.publisher_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>{item.publisher_id}</Link></td>
                <td align="right">
                    {this.state.campaign ? 
                        <Link to={this.props.getReportLink('clicks', campaignId, item.publisher_id, 0, 0, formattedStartDate, formattedEndDate)} onClick={this.toggle}>{Intl.NumberFormat('en-GB').format(item.clicks)}</Link>
                    :
                        <React.Fragment>{Intl.NumberFormat('en-GB').format(item.clicks)}</React.Fragment>
                    }
                </td>
                <td align="right"><Link to={this.props.getReportLink('conversions', campaignId, item.publisher_id, 0, 0, formattedStartDate, formattedEndDate)} onClick={this.toggle}>{Intl.NumberFormat('en-GB').format(item.leads)}</Link></td>
                <td align="right">{parseFloat(item.conversion*100).toFixed(2)}%</td>
                <td align="right">${parseFloat(item.epc).toFixed(2)}</td>  
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.cost)}</td>
                <td className="pr-2"><ReportDropdown offerId={campaignId} affiliateId={item.publisher_id} creativeId={this.props.creativeId} landingPageId={this.props.landingPageId} startDate={formattedStartDate} endDate={formattedEndDate} getReportLink={this.props.getReportLink} /></td>
            </tr>
        ))
    }

    render() {

        return (
            <React.Fragment>

                {this.state.dataLoaded && this.state.campaign &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Affiliate Report for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link> &nbsp;
                        <Link to={this.props.getCancelUrl('affiliates', 'offer', this.state.campaign.campaign_id, 0, this.props.creativeId, this.props.landingPageId, this.props.startDate, this.props.endDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                <table className="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Affiliate ID</th>
                            <th scope="col" className="text-right">Clicks</th>
                            <th scope="col" className="text-right">Leads</th>
                            <th scope="col" className="text-right">CR</th>
                            <th scope="col" className="text-right">EPC</th>
                            <th scope="col" className="text-right">Sales</th>
                            <th scope="col" className="text-right">Cost</th>
                            <th scope="col" className="text-right">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colSpan="8">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>

                                        {this.getReportOutput()}

                                        <tr className="font-weight-bold">
                                            <td>Totals</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.clicks)}</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.leads)}</td>
                                            <td align="right">{parseFloat(this.state.totals.conversion*100).toFixed(2)}%</td>
                                            <td align="right">${parseFloat(this.state.totals.epc).toFixed(2)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.sales)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.cost)}</td>                                                                                       
                                            <td align="right">&nbsp;</td>
                                        </tr>

                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colSpan="10"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>

            </React.Fragment>
        );
    }
}

export default ReportAffiliates;