import React from 'react';

const integrationPixel = (props) => {
    let url;

    if (props.trackingType === 'L') {
        url = `<img src="https://www.maxbounty.com/lead.asp?o=12345&r=18&d=137&s=25.00" border=0 width=0 height=0>;`;
    } else if (props.trackingType === 'I') {
        url = `<iframe src="https://www.maxbounty.com/lead_if.asp?o=12345&r=18&d=137&s=25.00" width=1 height=1 frameborder=0></iframe>;`;
    }

    return (
        <div>
            <div className="modal-header">
                <h5 className="font-weight-bold">Using your MaxBounty Postback</h5>
            </div>
            <div className="modal-body">
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 1</h5>
                    <p className="my-3">When a potential customer clicks on one of our affiliate links, we drop a cookie on their browser and then they are sent to your landing page.</p>
                </div>
                <hr />
                <div className="py-3">
                    <h5 className="font-weight-bold mb-underline">Step 2</h5>
                    <p className="my-3">When your customer arrives on your "Thank You" page, you must simply place the postback code on that thank you page to inform us of the conversion.</p>
                    <p>You can find your customized postback code by clicking the "Get Code" link for each rate.</p>
                    <p className="font-weight-bold">The following is just an example of how your code may look.</p>
                    <div style={{ background: "RGBA(0,0,0,0.05)", padding: "5px 10px", margin: "auto", display: "inline-block", color: "RGBA(0,0,0,0.6)", fontSize: "1.1em"}}>
                        <pre style={{wordWrap:'break-word', whiteSpace:'pre-wrap'}}>
                            &lt;html&gt;<br />
                            &nbsp; &lt;head&gt;<br />
                            &nbsp; &lt;/head&gt;<br />
                            &nbsp; &lt;body&gt;
                                    <br /><br />&nbsp;&nbsp;...Your thank you page code...<br /><br />
                            &nbsp;&nbsp;
                                    {url}
                            <br /><br />
                            &nbsp; &lt;body&gt;<br />
                            &lt;/html&gt;
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default integrationPixel;