import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../component/Logo/Logo';
import AccountDropdown from '../AccountDropdown/AccountDropdown';

import './Toolbar.css';

class Toolbar extends Component {
    render() {
        return (
            <nav className="navbar Toolbar fixed-top p-0">
                <Link className="navbar-brand mb-icon mr-0" to="/dashboard">
                    <Logo />
                </Link>
                <div className="px-4 desktopOnly">
                    <AccountDropdown />
                </div>
            </nav>
        );
    }
}

export default Toolbar;