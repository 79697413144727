import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../component/UI/Loading/Loading';
import { verifyToken, clearLocalStorageAndReload } from '../../../assets/JS/functions';
import { X } from 'react-feather';
import moment from 'moment';

class ReportCreatives extends Component {

    state = {
        dataLoaded: false,
        report: [],
        campaign: []
    }

    componentDidMount() {
        this.setState({
            dataLoaded: false
        })

        const mbToken = localStorage.getItem('mb-auth-token');        

        let page = this.props.page - 1;

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/creatives?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=${page}`;

        if (this.props.offerId) {
            fetchUrl = fetchUrl + `&oid=${this.props.offerId}`
        }

        if (this.props.affiliateId){
            fetchUrl = fetchUrl + `&aid=${this.props.affiliateId}`;
        }
        
        verifyToken().then(result => {
            if (result === true) {
               return fetch(fetchUrl, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': mbToken
                    }
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result["success"] === true) {
                                this.props.handleDownloadData(result);

                                // Update the current report with the data.
                                this.setState({
                                    dataLoaded: true,
                                    report: result.report,
                                    campaign: result.campaign,
                                    totals: result.totals
                                });
                            } else if (result["success"] === false) {
                                if (result.code !== 401) {
                                    this.props.showSystemMessage("error", "There was an error fetching the data. Please try again.");
                                }
                            } else {
                                this.props.showSystemMessage("error", result.errors.message);
                            }
                        });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            console.log('Error in ReportCreatives: ', error);
            this.props.showSystemMessage("error", "Connection error.");
        });
    }   

    render() {

        let formattedStartDate = moment(this.props.startDate).format("YYYY-MM-DD");
        let formattedEndDate = moment(this.props.endDate).format("YYYY-MM-DD");

        return (
            <React.Fragment>

                {this.state.dataLoaded && this.state.campaign &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Creative Report for: <Link to={`/campaign/${this.state.campaign.campaign_id}`}>{this.state.campaign.name}</Link> &nbsp;
                        <Link to={`/reports/earnings?startDate=${formattedStartDate}&endDate=${formattedEndDate}`}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                {this.state.dataLoaded && this.props.affiliateId > 0 &&
                    <div className="alert alert-info m-2 d-inline-block">
                        Affiliate ID: {this.props.affiliateId} &nbsp;
                        <Link to={this.props.getCancelUrl('creatives', 'affiliate', this.state.campaign.campaign_id, 0, 0, this.props.landingPageId, formattedStartDate, formattedEndDate)}>
                            <X size={20} />
                        </Link>
                    </div>
                }

                <table className="table table-striped table-card table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Creative</th>
                            <th scope="col" className="text-right">Clicks</th>
                            <th scope="col" className="text-right">Leads</th>
                            <th scope="col" className="text-right">CR</th>
                            <th scope="col" className="text-right">EPC</th>
                            <th scope="col" className="text-right">Sales</th>
                            <th scope="col" className="text-right">Cost</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {this.state.report.length === 0 ?
                                    <tr>
                                        <td colSpan="7">No stats for this date range.</td>
                                    </tr>
                                    :
                                    <React.Fragment>

                                        {this.state.report.map(item => (
                                            <tr key={item.creative_id}>                                                
                                                <td>{item.creative_name}</td>
                                                <td align="right"><Link to={this.props.getReportLink('clicks', this.state.campaign.campaign_id, this.props.affiliateId, item.creative_id, 0, formattedStartDate, formattedEndDate)}>{Intl.NumberFormat('en-GB').format(item.clicks)}</Link></td>
                                                <td align="right"><Link to={this.props.getReportLink('conversions', this.state.campaign.campaign_id, this.props.affiliateId, item.creative_id, 0, formattedStartDate, formattedEndDate)}>{Intl.NumberFormat('en-GB').format(item.leads)}</Link></td>
                                                <td align="right">{parseFloat(item.conversion*100).toFixed(2)}%</td>
                                                <td align="right">${parseFloat(item.epc).toFixed(2)}</td>  
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.sales)}</td>
                                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(item.cost)}</td>                                                
                                            </tr>
                                        ))}

                                        <tr className="font-weight-bold">
                                            <td>Totals</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.clicks)}</td>
                                            <td align="right">{Intl.NumberFormat('en-GB').format(this.state.totals.leads)}</td>
                                            <td align="right">{parseFloat(this.state.totals.conversion*100).toFixed(2)}%</td>
                                            <td align="right">${parseFloat(this.state.totals.epc).toFixed(2)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.sales)}</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.state.totals.cost)}</td>                                            
                                        </tr>

                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <tr>
                                <td colSpan="10"><Loading /></td>
                            </tr>
                        }

                    </tbody>
                </table>

            </React.Fragment>
        );
    }
}

export default ReportCreatives;