import React from 'react';

import AccountDropdown from '../AccountDropdown/AccountDropdown';
import NavigationDropdown from './NavigationDropdown/NavigationDropdown';

import './BottomToolbar.css';

const bottomToolbar = () => (
    <nav className="bottom-nav navbar fixed-bottom justify-content-around">
        <div className="text-white">
            <span className="mobile-only"><AccountDropdown /></span>
        </div>
        <div className="text-white">
            <span className="mobile-only"><NavigationDropdown /></span>
        </div>
    </nav>
);

export default bottomToolbar;