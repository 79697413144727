import React, { Component } from 'react';
import CountryList from '../../data/countrylist';

class Countries extends Component {
    state = {
        countries: CountryList.countries
    }

    render() {
        return (
            <React.Fragment>
                {this.state.countries.map(country => <option key={country.code} value={country.code}>{country.name}</option>)}
            </React.Fragment>
        );
    }
}

export default Countries;