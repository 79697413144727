import React, { Component } from 'react';

import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import Login from './container/Login/Login';
import Layout from './hoc/Layout/Layout';
import Dashboard from './container/Dashboard/Dashboard';
import Reports from './container/Reports/Reports';
import Campaigns from './container/Campaigns/Campaigns';
import Footer from './component/Footer/Footer';
import CampaignPage from './container/CampaignPage/CampaignPage';
import UserDetails from './component/UserDetails/UserDetails';
import LoadingPage from './component/UI/LoadingPage/LoadingPage';
import SystemMessage from './component/SystemMessage/SystemMessage';
import Invoices from './container/Invoices/Invoices';
import AccountBalance from './container/AccountBalance/AccountBalance';
import Settings from './container/Settings/Settings';
import Users from './container/Users/Users';
import OttLogin from './container/OttLogin/OttLogin';
import FileUpload from './container/FileUpload/FileUpload';

import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './App.css';

//fab imports all of the brand icons. You use it differently than other icons. example: icon={['fab','apple']}
library.add(faSpinner, fab);


class App extends Component {

  state = {
    dataLoaded: true,    
    advertiser: {
      city: "",
      company: "",
      country_code: "",
      mobile_phone: "",
      name: "",
      phone: "",
      state: "",
      street_address_1: "",
      street_address_2: "",
      zip_code: ""
    },
    users: {
      id: 0,
      e_mail: "",
      role: "",
      status: ""
    },
    systemMessage: {
      display: false,
      type: "",
      message: ""
    }
  }

  checkAuth = () => {
    const mbToken = localStorage.getItem('mb-auth-token');
    const authToken = !localStorage.getItem('mb-t') ? '' : localStorage.getItem('mb-t');

    let decodedToken = '';

    try {
      decodedToken = CryptoAES.decrypt(authToken, CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoENC);
    } catch (error) {
      decodedToken = '';
    }

    let isAuth = false;

    if (mbToken && (mbToken === decodedToken)) {
      isAuth = true;
    }

    return isAuth;
  }

  fetchAdvertiserData = () => {
    // Check to see if localstorage has been set
    // THIS IS SET UPON LOGIN AT api/authentication
    const mbToken = localStorage.getItem('mb-auth-token');

    let isAuth = this.checkAuth();
    //isAuth = true; /* For Testing */

    // if (mbToken) {
    //   isAuth = true;
    // }

    if (isAuth) {

      this.setState({
        dataLoaded: false
      });

      fetch(`${process.env.REACT_APP_API_URL}/advertiser`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-app-token': mbToken
        }
      })
        .then(res => res.json())
        .then(result => {
          if (result["success"] === true) {
            this.setState({
              dataLoaded: true,
              advertiser: result.advertiser,
              users: result.user,
              systemMessage: this.state.systemMessage
            });
          } else if (result["success"] === false) {
            this.showSystemMessage("error", "There was an error signing in. Please try again.");
            this.logout();
            this.setState({
              dataLoaded: true
            });
          } else {
            this.showSystemMessage("error", result.errors.message);
            this.logout();
            this.setState({
              dataLoaded: true
            });
          }
        })
        .catch(error => {
          console.log('Error in App.js:', error);
          this.showSystemMessage("error", "Connection error.");
          this.setState({
            dataLoaded: true
          });
        });

    } else {
      this.setState({
        ...this.state,
        logout:true
      })
    }
  }

  componentDidMount(){
    this.fetchAdvertiserData();
  }

  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location){
      this.fetchAdvertiserData();
    }
  }

  logout = () => {
    localStorage.removeItem('mb-auth-token');
    localStorage.removeItem('mb-t');
  }

  showSystemMessage = (type, message) => {
    // Available types
    // "success", "error"
    this.setState({
      systemMessage: {
        display: true,
        type: type,
        message: message
      }
    });
  }

  hideSystemMessage = () => {
    this.setState({
      systemMessage: {
        display: false,
        type: this.state.systemMessage.type,
        message: this.state.systemMessage.message
      }
    });
  }

  handleNameChange = (name) => {
    this.setState({
      advertiser: { ...this.state.advertiser, name: name },
      systemMessage: this.state.systemMessage
    });
  }

  render() {
    return (
      <div className="App">

        {this.state.systemMessage.display && (
          <SystemMessage type={this.state.systemMessage.type} hideSystemMessage={this.hideSystemMessage}>
            {this.state.systemMessage.message}
          </SystemMessage>
        )}

        { this.checkAuth() ?
          <Layout advertiser={this.state.advertiser} showSystemMessage={this.showSystemMessage}>
            <UserDetails advertiser={this.state.advertiser} />
            <Switch>
              <Route path="/dashboard" render={() => <Dashboard advertiser={this.state.advertiser} showSystemMessage={this.showSystemMessage} />} />
              <Route path="/campaign/:campaignId" render={(props) => <CampaignPage advertiserId={this.state.advertiser.advertiser_id} campaign_id={props.match.params.campaignId} showSystemMessage={this.showSystemMessage} />} />
              <Route path="/reports/:type?" render={(props) => <Reports key={`${props.match.params.type}/${props.location.search}`} type={props.match.params.type} location={props.location} history={props.history} showSystemMessage={this.showSystemMessage} />} />
              <Route path="/invoices" render={() => <Invoices showSystemMessage={this.showSystemMessage} />} />
              <Route path="/AccountBalance" render={() => <AccountBalance showSystemMessage={this.showSystemMessage} />} />
              <Route path="/campaigns" render={(props) => <Campaigns showSystemMessage={this.showSystemMessage} />} />
              <Route path="/settings" render={() => <Settings advertiser={this.state.advertiser} users={this.state.users} showSystemMessage={this.showSystemMessage} editName={this.handleNameChange} />} />
              <Route path="/users" render={() => <Users users={this.state.users} advertiser={this.state.advertiser} showSystemMessage={this.showSystemMessage} />} />
              <Route path="/fileupload" render={() => <FileUpload showSystemMessage={this.showSystemMessage} />} />
              <Route path="/" render={() => <Redirect to={{ pathname: "/dashboard" }} />} />
            </Switch>
            <Footer />
          </Layout>
          :
          this.state.dataLoaded ?
            <Switch>
              <Route path="/ottlogin" render={() => <OttLogin />} />
              <Route path="/" render={() => <Login showSystemMessage={this.showSystemMessage} />} />              
            </Switch>
            :
            <Route path="/*" component={LoadingPage} />
        }
      </div>
    );
  }
}

export default withRouter(App);
